package apofnj.pages.cd

import apofnj.buildTitleTag
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.b
import react.dom.div
import react.dom.h2
import react.dom.p
import react.helmet.helmet

class CDIndex : RComponent<RProps, RState>() {
    private val title = "Construction Documentation"
    override fun RBuilder.render() {
        div("PAGE CDIndex") {
            helmet(title = buildTitleTag(title))
            h2 {
                +title
            }
            p{
                +"Need a simple to use AND reliable construction camera? Our true Plug & Play system is the easiest to use construction camera on the market. All that you have to do after positioning it, is plug it in…  No more worries about setup, and since your new construction camera is guaranteed for the life of your job, no more worries about reliability!"
            }
            p{
                +"Fast scheduling, thanks to our own corporate aircraft."
            }
            p{
                +"Fast processing of your photos, thanks to our in house processing team who work on the latest Apple computers."
            }
            p{
                +"Free and easy downloads, thanks to our online ftp server."
            }
            p{
                b{
                    +"Drones are great for tight closeups or if you have a limited budget, but where they really excel, is with video. "
                }
            }
        }
    }
}