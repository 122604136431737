package apofnj

import apofnj.helpers.ID_HEADER
import apofnj.store.ElementSizeCTX
import apofnj.store.Store
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import react.router.dom.routeLink

interface NavBarState : RState {
    var menuOpen:Boolean
    var cpShow:Boolean
    var creShow:Boolean
    var activePage:String
}

class NavBar : RComponent<RProps, NavBarState>() {
    override fun NavBarState.init() {
        menuOpen = false
        cpShow = false
        creShow = false
    }

    override fun componentDidMount() {
        Store.registerCallback("NavBar", ::onStoreChange)
        ElementSizeCTX.setNavHeight(getElementDimensionById("NAVBAR"))
    }

    override fun componentWillUnmount() {
        Store.unRegisterCallback("NavBar")
    }

    private fun onStoreChange(){
        setState {
            activePage = Store.getCurentPage()
            menuOpen = false
            cpShow = false
            creShow = false
        }
    }

    private fun resetState(){
        setState {
            menuOpen = false
            cpShow = false
            creShow = false
        }
    }

    private fun RBuilder.callUs(){
        span("lable"){
            +"Call Us Today: "
        }
        +"(908) 730-0202"
    }

    private fun RBuilder.getCRELinks(){
        a {
            attrs.onClickFunction = {
                setState {
                    creShow = !state.creShow
                    cpShow = false
                }
            }
            +"Commercial Real Estate"
            i("fa fa-caret-down"){  }
        }
        div("dropDown ${if(state.creShow) "" else "displayNone"}"){
            div("content"){
                routeLink(to = NAV_KEY_COMMERCIAL_REAL_ESTATE) { +"CRE Home" }
                routeLink(to = NAV_KEY_CRE_AERIAL) { +"Aerial" }
                routeLink(to = NAV_KEY_CRE_DRONE) { +"Drone" }
                routeLink(to = NAV_KEY_CRE_ARCH) { +"Architectural" }
/*              routeLink(to = NAV_KEY_CRE_INTERIOR) { +"Interior" }
                routeLink(to = NAV_KEY_CRE_MATTER_PORT) { +"Matterport" }*/
                routeLink(to = NAV_KEY_CRE_VIDEO) { +"Video" }
            }
        }
    }

    private fun RBuilder.getCDLinks(){
        a {
            attrs.onClickFunction = {
                setState {
                    cpShow = !state.cpShow
                    creShow = false
                }
            }
            +"Construction Documentation"
            i("fa fa-caret-down"){  }
        }
        div("dropDown ${if(state.cpShow) "" else "displayNone"}"){
            div("content"){
                routeLink(to = NAV_KEY_CONSTRUCTION_PROGRESS) { +"CD Home" }
                routeLink(to = NAV_KEY_CP_AERIAL) { +"Aerial" }
                routeLink(to = NAV_KEY_CP_GROUND) { +"Ground" }
                routeLink(to = NAV_KEY_CP_MATTER_PORT) { +"Matterport" }
                routeLink(to = NAV_KEY_CP_CONSTRUCTION_CAM) { +"Worksite WebCams" }
            }
        }
    }

    override fun RBuilder.render() {
        div {
            attrs.id = ID_HEADER
            img(src = "img/logo.png", classes = "logo") {
                attrs.id = "NAV_LOGO"
            }
            div("desktopCallUs"){
                callUs()
            }
            div("hamburger"){
                attrs.onClickFunction = {
                    setState {
                        menuOpen = !state.menuOpen
                    }
                }
                i("fas fa-bars"){   }


            }
            div("navigation ${if (state.menuOpen) "" else "displayNoneNavRoot"}"){
                routeLink(to =NAV_KEY_HOME) { +"Home" }
                routeLink(to = NAV_KEY_SITE_LOCATOR) { +"Site Locator" }
                routeLink(to = NAV_KEY_ABOUT_US) { +"About" }
                if(Store.isCLientPhone()){
                    getCRELinks()
                }else{
                    span(classes = "ddWrapper"){
                        getCRELinks()
                    }
                }
/*                if(Store.isCLientPhone()){
                    getCDLinks()
                }else{
                    span(classes = "ddWrapper"){
                        getCDLinks()
                    }
                }*/
/*                routeLink(to = NAV_KEY_MAPPING, className = "") { +"Mapping ${Typography.amp} Surveying" }*/
                routeLink(to = NAV_KEY_CONTACT, className = "") { +"Contact" }
            }
            div("callUs"){
                callUs()
            }
        }
    }
}