package apofnj.modal

import apofnj.store.ElementSizeCTX
import apofnj.store.ModalCtx
import apofnj.store.Store
import kotlinx.css.*
import kotlinx.css.LinearDimension.Companion.fillAvailable
import kotlinx.html.id
import react.*
import styled.css
import styled.styledDiv

interface ModalFullProps:RProps{

}

interface ModalFullState: RState {
    var height : LinearDimension?
    var width : LinearDimension?
    var open: Boolean
}

class ModalFull : RComponent<ModalFullProps,  ModalFullState>() {
    override fun componentDidMount() {
        ElementSizeCTX.registerCallback("ModalFull", ::onSizeChange)
        ModalCtx.registerCallback("ModalFull", ::onCtxChange)
        onSizeChange()
        setState{
            open = ModalCtx.isOpen()
        }
    }

    fun onCtxChange(){
        setState {
            open = ModalCtx.isOpen()
        }
    }

    fun onSizeChange(){
        console.log("on size change")
        setState {

            if(ElementSizeCTX.getBox() != null){
                console.log("on size change SETSTATE ${(ElementSizeCTX.getBox()!!.height)}")
                height = 1.px * (ElementSizeCTX.getBox()!!.height)
                width = 1.px * (ElementSizeCTX.getBox()!!.width)
            }
        }
    }


    override fun RBuilder.render() {
        console.log("Modal", Store.isPhone())
        if(state.open){
            if(state.height != null){
                styledDiv {
                    attrs.id = "MODAL_FULL"
                    if(Store.isPhone()){
                        css {
                            height = state.height!!
                            width = state.width!!
                            zIndex = 10000
                        }
                    }else{
                        css {
                            height = state.height!!
                            width = state.width!!
                            zIndex = 10000
                        }
                    }

                    ModalCtx.getBody().invoke(this)
                }
            }
        }
    }
}