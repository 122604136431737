package apofnj

import apofnj.helpers.ID_FOOTER
import apofnj.store.ElementSizeCTX
import apofnj.store.Store
import kotlinx.html.id
import net.ideablender.apofnj.pojo.pullAppVersion
import react.*
import react.dom.div
import react.dom.span
import react.router.dom.routeLink


class Footer : RComponent<RProps, RState>() {
    override fun componentDidMount() {
        ElementSizeCTX.setFooterHeight(getElementDimensionById("FOOTER"))
    }

    override fun componentWillUnmount() {
        Store.unRegisterCallback(key = "Footer")
    }

    override fun RBuilder.render() {
        div {
            attrs.id = ID_FOOTER
            div("copyright chunk"){
                +"Aerial Photos of NJ - Excellence in Commercial Real Estate Photography & Video - since 1999"
            }
            div("socials chunk"){
                span("fab fa-facebook-square") {  }
                span("fab fa-twitter-square middle") {  }
                span("fab fa-instagram") {  }
            }
            div("policySitemapVersion chunk"){
                routeLink(to = NAV_KEY_PRIVACY_POLICY, className = "") { +"Privacy Policy" }
                span("separator"){+"|"}
                routeLink(to = NAV_KEY_SITE_MAP, className = "") { +"SiteMap" }
                div("version"){
                    val appVersion = pullAppVersion()
                    +"Version: ${appVersion.versionMajor}.${appVersion.versionMinor}.${appVersion.versionBuild}"
                }
            }

        }
    }
}