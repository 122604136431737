package apofnj.pages

import apofnj.buildTitleTag
import kotlinx.html.id
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.*
import react.helmet.helmet

class About : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        div("PAGE") {
            attrs.id = "ABOUT"
            helmet(title = buildTitleTag("About Us"))
            h2 {
                +"About Us"
            }
            div("imgWrapper"){
                img(src = "img/JohnPortrait.jpg") {  }
                p{
                    +"John Majoris, CEO"
                }
            }
            p{
                +"From Teenage Shutterbug, to Professional Aerial Photographer."
            }

            p{
                +"John received his first film camera, a 35 mm Konica Autoreflec TC, when he was a teenager. Right away he knew what he wanted to do with his life, and before long he’d converted a bathroom in his basement to a darkroom and started developing B&W photographs of animals that he’d taken at various zoos around the NY area. But the realities of being a full-blown nature photographer didn’t coincide with having a family, so he shelved that idea."
            }
            p{
                +"His passion for all things flying led to him take flying lessons, and he earned his private pilot’s license in 1996 at Trenton Mercer County airport. A few years later he earned his commercial certificate, which opened up the skies for him by allowing him to fly professionally."
            }
            p{
                +"In 1999 John combined his two passions and started Aerial Photos of NJ. He didn’t know anything about the aerial photography business, so he decided to attend a Professional Aerial Photographers Association convention in Vegas. His goal was to see firsthand if anyone could actually make a good living at aerial photography, or if these folks just flew around taking photos of people’s homes and knocking on doors a few days later to sell the homeowners framed prints. He had no interest in doing that, and he was pleasantly surprised to find himself amongst a room full of professionals who owned real businesses. From that point on, he was all in!"
            }
            p{
                +"He credits the members of the Professional Aerial Photographers Association with helping him get his business off the ground. Their educational events were instrumental in giving him the ability to hit the ground running and grow his business. In a little over a year, he had enough business to buy his first aircraft, a 1975 Cessna Cardinal, which he owns to this day."
            }
            p{
                +"A lot has changed in the aerial photography business since John started, cameras transitioned from film to digital, Google Earth became a household name as well as a source of competition, and the onslaught of inexpensive drone photographers chipped away at his business. "
            }

            p("ulHead"){
                +"But what hasn’t changed over the 20+ years of business is the Aerial Photos of NJ mission statement. From day one it’s been:"
            }
            ul{

                li{
                    +"To provide commercial real estate professionals with outstanding marketing media in a timely manner and at a reasonable cost. "
                }
                li{
                    +"To service our clients beyond expectations and build long standing relationships with our fellow commercial real estate team members"
                }
            }
            p{
                +"Click on the site locator button and let us show you how we live this mission, everyday!"
            }
        }
    }
}