package apofnj.components.imageGallery

import apofnj.getPathForImage
import apofnj.store.GalleryCTX
import apofnj.store.GalleryCTXAction
import apofnj.store.ModalCtx
import apofnj.store.Store
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.dto.GalleryDTO
import net.ideablender.apofnj.dto.PhotoDTO
import react.*
import react.dom.*



interface ImageGalleryProps : RProps {
    var gallery: GalleryDTO
    var photos: List<PhotoDTO>
}

class ImageGallery : RPureComponent<ImageGalleryProps, RState>() {

    override fun componentDidMount() {
        GalleryCTX.handleAction(GalleryCTXAction.HandleThumbDblClick(props.photos.first()))
        GalleryCTX.registerCallback("ImageGallery", ::handleGalleryCtxChange)
        initGallery()
    }

    fun handleGalleryCtxChange(){
        forceUpdate()
    }


    fun initGallery(list: List<String> = listOf()) {
        GalleryCTX.handleAction(GalleryCTXAction.HandleBuildingOfMaps(props.photos))
    }

    override fun RBuilder.render() {
        console.log(Store.isCLientPhone())
        div("") {
            attrs.id = "ImageGallery"
            div("galleryHeader") {
                h2 {
                    +props.gallery.description
                }
                i(classes = "fas fa-cog") {
                    attrs.onClickFunction = {
                        ModalCtx.openModal {
                            child(ImageGalleryMenu::class) {}
                        }
                    }
                }
            }
            if(Store.isCLientPhone() == false){
                div("chosenImage") {
                    if(GalleryCTX.getState().chosenPhoto != null){
                        val chosen = GalleryCTX.getState().chosenPhoto ?: throw Exception("No chosen Image")
                        img(src = getPathForImage(chosen), classes = "responsiveImg"){
                            attrs.alt = "alt Tag"
                        }
/*                        if(GalleryCTX.getState().showTitle){
                            h3{
                                label{
                                    +"Title:"
                                }
                                +chosen.title
                            }
                            p("description"){
                                +chosen.description
                            }
                        }
                        if(GalleryCTX.getState().showTags){
                            div("tagWrapperMainImg"){
                                p{
                                    label{
                                        +"Tags:"
                                    }
                                    chosen.tags.forEachIndexed { index, dtoMin ->
                                        span("tag"){
                                            +dtoMin.detail
                                            if(index < (chosen.tags.size -1)){
                                                +", "
                                            }
                                        }
                                    }
                                }
                            }
                        }*/
                    }
                }
            }
            child(ThumbContainerWrapper::class){ }
        }
    }
}
