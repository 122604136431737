package apofnj.pages.cd

import apofnj.buildTitleTag
import apofnj.components.imageGallery.ImageGalleryWrapper
import net.ideablender.apofnj.pojo.GalleryName
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.div
import react.dom.h2
import react.helmet.helmet

class CDGround : RComponent<RProps, RState>() {
    private val title = "Construction Documentation | Ground"
    override fun RBuilder.render() {
        div("PAGE CDGround") {
            helmet(title = buildTitleTag(title))
            h2 {
                +title
            }
/*            child(ImageGalleryWrapper::class) {
                attrs.galleryName = GalleryName.CD_GROUND
            }*/
        }
    }
}