package apofnj.components.imageGallery

import apofnj.store.GalleryCTX
import apofnj.store.GalleryCTXAction
import apofnj.store.ModalCtx
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*

class ImageGalleryMenu : RComponent<RProps, RState>() {
    override fun componentDidMount() {
        GalleryCTX.registerCallback("ImageGalleryMenu", ::handleGalleryCtxChange)
    }

    fun handleGalleryCtxChange(){
        forceUpdate()
    }

    fun RBuilder.getTagButton(){
        var showTagsBtnTxt = "Hide Tags"
        var styleClassTags = "button-error"
        if(GalleryCTX.getState().showTags == false){
            showTagsBtnTxt = "Show Tags"
            styleClassTags = "button-success"
        }
        button(classes = styleClassTags) {
            +showTagsBtnTxt
            attrs.onClickFunction = {
                GalleryCTX.handleAction(GalleryCTXAction.HandleShowTagsClicked())
                ModalCtx.closeModal()
            }
        }
    }

    fun RBuilder.getTitleButton(){
        var showTitleBtnTxt = "Hide Titles"
        var styleClassTitle = "button-error"
        if(GalleryCTX.getState().showTitle == false){
            showTitleBtnTxt = "Show Titles"
            styleClassTitle = "button-success"
        }
        button(classes = styleClassTitle) {
            +showTitleBtnTxt
            attrs.onClickFunction = {
                GalleryCTX.handleAction(GalleryCTXAction.HandleShowTitleClicked())
                ModalCtx.closeModal()
            }
        }
    }

    fun RBuilder.wantedTag(str:String){
        span(classes = "tagButton") {
            +str
            attrs.onClickFunction = {
                GalleryCTX.handleAction(GalleryCTXAction.HandleAddWantedTag(str))
            }
        }
    }

    fun RBuilder.unWantedTag(str:String){
        span(classes = "tagButton unwantedHolder") {
            +str
            attrs.onClickFunction = {
                GalleryCTX.handleAction(GalleryCTXAction.HandleRemoveWantedTag(str))
            }
        }
    }

    override fun RBuilder.render() {
        div {
            attrs.id = "ImageGalleryMenu"
            div("body"){
                h3 { + "Gallery Settings" }
                h4 { + "WANTED TAGS (click tag to remove it)" }
                div("tagHolder ${if(GalleryCTX.getState().unwantedTags.size > 0) "scrollable" else ""}"){
                    GalleryCTX.getState().wantedTags.sorted().forEach { tagStr ->
                        wantedTag(tagStr)
                    }
                }
                if(!GalleryCTX.getState().unwantedTags.isEmpty()){
                    hr {  }
                    h4(classes = "unwantedHeader") { + "UNWANTED TAGS" }
                    div("tagHolder unwantedHolder"){
                        GalleryCTX.getState().unwantedTags.sorted().forEach { tagStr ->
                            unWantedTag(tagStr)
                        }
                    }
                }
            }
            div{
                attrs.id = "ImageGalleryMenuButtonRow"
                getTagButton()
                getTitleButton()
                button(classes = "button-primary") {
                    +"Close"
                    attrs.onClickFunction = {
                        ModalCtx.closeModal()
                    }
                }
            }
        }
    }
}