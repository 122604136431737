package apofnj.components.mapping

import apofnj.bindings.reactmapboxgl.ReactMapboxGl
import apofnj.bindings.reactmapgldraw.Draw
import apofnj.store.mapping.MapCtxAction
import apofnj.store.mapping.MapCtxState
import apofnj.store.mapping.MapStore
import apofnj.store.mapping.getFS
import kotlinx.html.id
import react.*
import react.dom.div
import react.dom.h1

interface MapHolderState : RState {
    var mapCtx: MapCtxState
}

class MapHolder : RComponent<RProps, MapHolderState>() {
    override fun MapHolderState.init() {
        mapCtx = MapStore.getCurrentState()
    }
    override fun componentDidMount() {
        MapStore.registerCallback("MapHolder", ::onMapStoreChange)
    }

    private fun onMapStoreChange() {
        setState {
            mapCtx = MapStore.getCurrentState()
        }
    }

    override fun RBuilder.render() {
        console.log("render", state.mapCtx.mapboxApiKey)
        val vp = state.mapCtx.viewport
        if(state.mapCtx.mapboxApiKey != null){
            div{
                attrs.id = "MapHolder"
                ReactMapboxGl {
                    attrs {
                        mapboxApiAccessToken = state.mapCtx.mapboxApiKey ?: throw Exception("No api key in Mapstore")
                        mapStyle = state.mapCtx.mapStyle.style
                        width = vp.width
                        height = vp.height
                        longitude = vp.longitude
                        latitude = vp.latitude
                        zoom = vp.zoom
                        onViewportChange = {
                            MapStore.handleAction(MapCtxAction.ChangeViewport(it))
                        }
                    }

                    Draw {
                        attrs {
                            onUpdate = {
                                if (it.editType == "addFeature" || it.editType == "movePosition") {
                                    MapStore.handleAction(MapCtxAction.ChangeFeatures(arrayOf(it.data.first())))
                                }
                            }
                            mode = state.mapCtx.modeCtx.mode
                            clickRadius = 12
                            features = state.mapCtx.features
                            featureStyle = getFS(state.mapCtx.modeCtx)
                        }
                    }
                }
            }
        }
    }
}

