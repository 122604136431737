package apofnj.components.imageGallery

import apofnj.getPathForThumb
import apofnj.store.ModalCtx
import kotlinx.browser.window
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.dto.PhotoDTO
import react.*
import react.dom.div
import react.dom.i
import react.dom.img

interface ImageGalleryPhotoViewerState : RState {
    var dto : PhotoDTO
    var index :Int
}

interface ImageGalleryPhotoViewerProps : RProps {
    var dtoMap:Map<Int,PhotoDTO>
    var chosen:Int
}

class ImageGalleryPhotoViewerNoImageInMapException(): Exception("Map passed to ImageGalleryPhotoViewer does not have dto at index.")
class ImageGalleryPhotoViewer : RComponent<ImageGalleryPhotoViewerProps, ImageGalleryPhotoViewerState>() {
    override fun componentDidMount() {
        window.setTimeout(handler = {
            console.log("foobar")
            window.scrollTo(0.0,1.0) }, timeout = 250)
        setState {
          dto = props.dtoMap.get(props.chosen) ?: throw ImageGalleryPhotoViewerNoImageInMapException()
          index = props.chosen
        }
    }

    override fun RBuilder.render() {
        div("ImageGalleryPhotoViewerControls"){
            i(classes = "fas fa-window-close"){ //<i class=""></i>
                attrs.onClickFunction = {
                    ModalCtx.closeModal()
                }
            }
            if(state.index != 0){
                i("fas fa-arrow-left"){
                    attrs.id = "arrowPrev"
                    attrs.onClickFunction = {
                        val newIdsx = state.index -1
                        setState {
                            dto = props.dtoMap.get(newIdsx) ?:  throw ImageGalleryPhotoViewerNoImageInMapException()
                            index = newIdsx
                        }
                    }
                }
            }
            if(state.index != props.dtoMap.count() -1){
                i("fas fa-arrow-right"){
                    attrs.id = "arrowNext"
                    attrs.onClickFunction = {
                        val newIdsx = state.index +1
                        setState {
                            dto = props.dtoMap.get(newIdsx) ?:  throw ImageGalleryPhotoViewerNoImageInMapException()
                            index = newIdsx
                        }
                    }
                }
            }
        }
        div {
            attrs.id = "ImageGalleryPhotoViewer"
            if(state.dto != undefined && state.dto != null){

            img(src = getPathForThumb(state.dto), classes = "rotatedImage"){}


            }
        }
    }
}