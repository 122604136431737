package apofnj.helpers

import apofnj.store.Observed
import kotlinx.browser.document
import kotlinx.browser.window

object BoundaryAwareSupport: Observed() {
    private var _outerDimmens: OuterDimmens = OuterDimmens()
    fun recordDivSizes(){
        console.log("recordDivSizes")
        val boxBrowser = Box(
            height = window.innerHeight,
            width = window.innerWidth)
        val headerEle = document.getElementById(ID_HEADER) ?: throw HtmlElementNotFoundForIdException(ID_HEADER)
        val footerEle = document.getElementById(ID_FOOTER) ?: throw HtmlElementNotFoundForIdException(ID_FOOTER)
        val boxHeader = Box(
            height = headerEle.clientHeight,
            width = headerEle.clientWidth
        )
        val boxFooter = Box(
            height = footerEle.clientHeight,
            width = footerEle.clientWidth
        )
        _outerDimmens = OuterDimmens(
            browserDimens = boxBrowser,
            headerDimens = boxHeader,
            footerDimens = boxFooter
        )
        console.log("""
            components.BoundaryAwareSupport.recordDivSizes():
            innerHeight: ${window.innerHeight}
            clientHeight: ${window.document.body?.clientHeight}
            browser : $boxBrowser
            header : $boxHeader
            footer : $boxFooter
        """.trimIndent())
        invokeCbs()
    }
    fun getAvailableDimmens():Box{
        val h:Int = _outerDimmens.browserDimens.height - _outerDimmens.headerDimens.height - _outerDimmens.footerDimens.height
        val w:Int =  _outerDimmens.headerDimens.width
        return Box(height = h, width = w)
    }
    fun getAvailableDimmensNoFooter():Box{
        val h:Int = _outerDimmens.browserDimens.height - _outerDimmens.headerDimens.height
        val w:Int =  _outerDimmens.headerDimens.width
        return Box(height = h, width = w)
    }
    fun getElementDimensionById(id: String): Box {
        val ele = document.getElementById(id)
        if(ele == null){
            throw HtmlElementNotFoundForIdException(id)
        }else{
            return Box(height = ele.clientHeight, width = ele.clientWidth)
        }
    }
    fun getOuterDimmens() = _outerDimmens
}
class HtmlElementNotFoundForIdException(id: String) : Exception("Could not find an HTML element for css id : $id")
data class OuterDimmens(
    val browserDimens:Box = Box(),
    val headerDimens:Box = Box(),
    val footerDimens:Box = Box()
)
enum class SizeAwarePref{
    WITHFOOTER,
    NOFOOTER
}
data class Box(
    val height:Int =0,
    val width:Int=0
)
val ID_HEADER = "NAVBAR"
val ID_FOOTER = "FOOTER"