package apofnj.components

import apofnj.helpers.BoundaryAwareSupport
import apofnj.helpers.Box
import apofnj.helpers.SizeAwarePref
import react.RProps
import react.RPureComponent
import react.RState
import react.setState

interface BoundaryAwareComponentState: RState {
    var boundary: Box
}
interface BoundaryAwareComponentProps: RProps {
    var pageTag: String
    var pref: SizeAwarePref
}
abstract class BoundaryAwareComponent<P : BoundaryAwareComponentProps, S : BoundaryAwareComponentState> : RPureComponent<P, S>() {
    override fun componentWillMount() {
        BoundaryAwareSupport.registerCallback(props.pageTag, ::onWindowSizeChange)
    }

    override fun componentWillUnmount() {
        BoundaryAwareSupport.unRegisterCallback(props.pageTag)
    }

    override fun componentDidMount() {
        onWindowSizeChange()
    }

    private fun setAvailableBoundary() {
        val _boundary = when (props.pref) {
            SizeAwarePref.WITHFOOTER -> BoundaryAwareSupport.getAvailableDimmens()
            SizeAwarePref.NOFOOTER -> BoundaryAwareSupport.getAvailableDimmensNoFooter()
        }
        setState {
            boundary = _boundary
        }
    }

    private fun onWindowSizeChange() {
        setAvailableBoundary()
    }
}