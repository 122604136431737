package apofnj.pages.cre

import apofnj.*
import apofnj.store.Store
import kotlinx.html.ButtonType
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import react.helmet.helmet
import react.router.dom.hashRouter
import react.router.dom.route
import react.router.dom.switch
import react.router.dom.useHistory

class CREIndex : RComponent<RProps, RState>() {
    private val title = "Commercial Real Estate"

    fun RBuilder.foobar(){

    }

    override fun RBuilder.render() {
        div("PAGE CREIndex") {
            helmet(title = buildTitleTag(title))
            h2 {
                +title
            }
            img(src="img/20070621_1100_Cover5000.jpg", classes = "responsiveImg"){}

            p{
                +"All the top commercial real estate brokerages firms and most of the commercial real estate developers in NJ,NY,PA, & CT use Aerial Photos of NJ’s services to help them market their properties. They understand that when it comes to marketing, nothing compares to a set of current professional aerial photos that show prospective buyer ALL the positive attributes of a location."
            }

            p{
                +"Need to show highway intersections within 3-4 miles of your location to show the transportation infrastructure? No problem. "
            }

            p{
                +"How about some great aerial photos that capture all of the rooftops in the area to show the potential labor pool? We’ve got you covered in that area as well."
            }
            p{
                +"Do you want closeup aerials to show off the beautiful exteriors of your Class A listing? Great! We capture outstanding detail with our professional line of Nikon lenses that go up to 600mm. And with our 45MP Nikon bodies, we can digitally zoom into your aerial photos even closer!"
            }
            hr {  }
            h3{
                +"Look at our portfolios and we think you’ll agree that we’re the right fit for your team!"
            }
            div("subCategories") {
                child(subCatAerial)
                child(subCatDrone)
                child(subCatArchitectural)
                child(subCatVideo)
            }
        }
    }
}



val subCatAerial = functionalComponent<RProps> {
    val history = useHistory()
    div("subcat") {
        attrs.onClickFunction = {
            history.push(NAV_KEY_CRE_AERIAL)
        }
        img(src = "img/Aerial_Thumb_Aerial-Photo-NJ-00019.jpg"){}
        p{
            +"Aerial Photography"
        }
    }
}

val subCatDrone = functionalComponent<RProps> {
    val history = useHistory()
    div("subcat") {
        attrs.onClickFunction = {
            history.push(NAV_KEY_CRE_DRONE)
        }
        img(src = "img/Drone_Thumb_Drone-Aerial-Photo-NJ-00089.jpg"){}
        p{
            +"Drone Photography"
        }
    }
}

val subCatArchitectural = functionalComponent<RProps> {
    val history = useHistory()
    div("subcat") {
        attrs.onClickFunction = {
            history.push(NAV_KEY_CRE_ARCH)
        }
        img(src = "img/Architectural_Thumb_Architectural-Photo-NJ-00295.jpg"){}
        p{
            +"Architectural Photography"
        }
    }
}

val subCatVideo = functionalComponent<RProps> {
    val history = useHistory()
    div("subcat") {
        attrs.onClickFunction = {
            history.push(NAV_KEY_CRE_VIDEO)
        }
        img(src = "img/Video_Thumb_Architectural-Photo-NJ-00200.jpg"){}
        p{
            +"Video"
        }
    }
}


