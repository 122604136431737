package apofnj.components.imageGallery

import apofnj.bindings.Boundary
import apofnj.store.GalleryCTX
import kotlinx.browser.window
import kotlinx.css.div
import kotlinx.html.id
import org.w3c.dom.Element
import org.w3c.dom.events.Event
import react.*
import react.dom.div
import react.dom.findDOMNode


data class ThumbContainerWrapperState  (
    var boundary: Boundary,
    var thumbContainerWrapperRef: Element?,
    ): RState



class ThumbContainerWrapper(props: RProps) : RPureComponent<RProps, ThumbContainerWrapperState>(props) {
    init{
        state = ThumbContainerWrapperState(Boundary(), createRef<Element>().current)
        state.thumbContainerWrapperRef?.addEventListener("resize", ::handleResize)
        GalleryCTX.registerCallback("ThumbContainerWrapper", ::handleGalleryCtxChange)
    }

    fun handleGalleryCtxChange(){
        reportDimmens()
        forceUpdate()
    }


    override fun componentDidMount() {
        GalleryCTX.registerCallback("ThumbContainerWrapper", ::handleGalleryCtxChange)
        window.addEventListener("resize",::handleResize)
        if(state.thumbContainerWrapperRef != null){
            reportDimmens()
        }
    }
    fun reportDimmens(){
        if(state.thumbContainerWrapperRef != null){
            val ctxState = GalleryCTX.getState()
            var widthOfThumbs = GalleryCTX.getWidthsOfThumbs()
            val divRev = state.thumbContainerWrapperRef ?: throw Exception("thumbContainerWrapperRef not loaded")
            var leftBound =  (widthOfThumbs - divRev.clientWidth) * -1
            val ooj = GalleryCTX.getWidthsOfThumbs() / GalleryCTX.getThumbCount()
            if(widthOfThumbs < divRev.clientWidth){
                leftBound = 0
            }
            setState {
                boundary = Boundary(
                    left = leftBound,
                    top = divRev.clientTop,
                    right = 0,
                    bottom = divRev.clientHeight
                )
            }
        }
    }

    fun handleResize(evt: Event){
        reportDimmens()
    }

    override fun RBuilder.render() {
        div{
            attrs.id = "ThumbContainerWrapper"
            ref{state.thumbContainerWrapperRef = findDOMNode(it) }
            child(ThumbContainer::class){
                attrs.boundary = state.boundary
            }
        }
    }
}