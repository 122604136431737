package apofnj.store


import apofnj.NAV_KEY_HOME
import apofnj.bindings.mobiledetect.MobileDetect
import apofnj.endpoint
import apofnj.jsonClient
import apofnj.store.mapping.MapCtxAction
import apofnj.store.mapping.MapStore
import apofnj.store.mapping.MapStyle
import io.ktor.client.HttpClient
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.browser.window
import net.ideablender.apofnj.dto.*
import net.ideablender.apofnj.pojo.GalleryName
import net.ideablender.apofnj.req.AddressRequest
import net.ideablender.apofnj.req.ContactFormREQ
import net.ideablender.apofnj.req.JobREQ
import net.ideablender.apofnj.statics.MSG_SERVER_CALL_WORKED
import net.ideablender.apofnj.statics.REST_HANDLE_MAPBOX_KEY
import kotlin.collections.List
import kotlin.collections.MutableList
import kotlin.collections.MutableMap
import kotlin.collections.MutableSet
import kotlin.collections.find
import kotlin.collections.firstOrNull
import kotlin.collections.forEach
import kotlin.collections.get
import kotlin.collections.map
import kotlin.collections.mutableListOf
import kotlin.collections.mutableMapOf
import kotlin.collections.mutableSetOf
import kotlin.collections.set
import kotlin.collections.toList
import kotlin.collections.toMap

enum class ServiceNames {
    GALLERY,
    PHOTO,
    TAG
}

enum class BrowserType {
    DESKTOP,
    PHONE,
    TABLET
}

object Store : Observed() {
    private var _currentPage: String = NAV_KEY_HOME
    private var serviceLoaded: MutableSet<ServiceNames> = mutableSetOf()

    val md: MobileDetect = MobileDetect(window.navigator.userAgent)

    fun getBrowserType(): BrowserType {
        if (md.phone() != null) return BrowserType.PHONE
        if (md.tablet() != null) return BrowserType.TABLET
        return BrowserType.DESKTOP
    }

    fun isCLientPhone() = md.phone() != null




    fun getCurentPage(): String = _currentPage

    fun setCurrentPage(page: String) {
        _currentPage = page
        invokeCbs()
    }

    fun setAppLoaded(service: ServiceNames) {
        serviceLoaded.add(service)
        if (serviceLoaded.size == 3) {
            invokeCbs()
        }
    }

    fun isServiceLoaded(sn: ServiceNames) = serviceLoaded.contains(sn)

    fun isPhone() = md.phone() != null

    fun isDesktop() = (isPhone() == false && isTablet() == false)

    fun isTablet() = md.tablet() != null


    fun getAppLoaded(): Boolean {
        return serviceLoaded.size == 3
    }
}

abstract class Observed {
    val cbMap: MutableMap<String, () -> Unit> = mutableMapOf()
    fun registerCallback(key: String, cb: () -> Unit) {
        cbMap[key] = cb
    }

    fun unRegisterCallback(key: String) {
        cbMap.remove(key)
    }

    fun invokeCbs() {
        cbMap.forEach {
            it.value.invoke()
        }
    }
}

interface RestInterface<T> {
    suspend fun fetchAll()
}

abstract class DataStore<T : DTO>(val restPath: String) : Observed(), RestInterface<T> {
    private val items: MutableMap<Int, T> = mutableMapOf()
    private var _chosenId: Int? = null

    fun getItemValues() = items.values

    fun setChosenId(id: Int?) {
        _chosenId = id
    }

    fun getChosenItem(): T? {
        return items[_chosenId]
    }


    fun findById(id: Int): T? = items[id]

    fun getAll(): List<T> = items.values.toList()

    fun commitAll(enitiyList: List<T>) {
        items.putAll(enitiyList.map { it.id to it }.toMap())
        invokeCbs()
    }
}

fun getServerBaseUrl(): String {
    console.log("window.location.hostname",window.location.hostname)
    return if (window.location.hostname == "localhost") {
        "http://localhost:8080/api"
    } else {
        console.log("NOT LOCAL TOOOOOOO ${window.location.hostname}")
        //"$endpoint/api",
        "https://admin.aerialphotosofnj.com/api"
    }
}

object GalleryService : DataStore<GalleryDTO>(GalleryDTO.getRestPath()) {

    fun getGallery(gn: GalleryName): GalleryDTO? {
        return getItemValues().find { it.name == gn.display }
    }

    override suspend fun fetchAll() {
        val url  = "${getServerBaseUrl()}${GalleryDTO.getRestPath()}"
        val resp = jsonClient.get<List<GalleryDTO>>(urlString = url)
        console.log(resp)
        commitAll(resp)
        if (!Store.getAppLoaded()) {
            Store.setAppLoaded(ServiceNames.GALLERY)
        }
    }
}

object PhotoService : DataStore<PhotoDTO>(PhotoDTO.getRestPath()) {
    override suspend fun fetchAll() {
        val resp = jsonClient.get<List<PhotoDTO>>(urlString = "${getServerBaseUrl()}${PhotoDTO.getRestPath()}")
        console.log("photos?", resp)
        commitAll(resp)
        if (!Store.getAppLoaded()) {
            Store.setAppLoaded(ServiceNames.PHOTO)
        }
    }

    fun getPhotoByFileName(fileName: String): PhotoDTO? {
        return getAll().firstOrNull { it.fileName == fileName }
    }

    fun getPhotosForGallery(galleryDTO: GalleryDTO): List<PhotoDTO> {
        val retList: MutableList<PhotoDTO> = mutableListOf()
        galleryDTO.photos.forEach {
            retList.add(PhotoService.findById(it.id) ?: throw Exception("Could not find a photo with id : ${it.id}"))
        }
        return retList
    }
}

object TagService : DataStore<TagDTO>(TagDTO.getRestPath()) {
    override suspend fun fetchAll() {
        val resp = jsonClient.get<List<TagDTO>>(urlString = "${getServerBaseUrl()}${TagDTO.getRestPath()}")
        commitAll(resp)
        if (!Store.getAppLoaded()) {
            Store.setAppLoaded(ServiceNames.TAG)
        }
    }
}

object ServerClient {
    suspend fun sendContactForm(req: ContactFormREQ) : Boolean {
        val resp = jsonClient.post<Boolean>("${getServerBaseUrl()}${ContactFormDTO.getRestPath()}") {
            contentType(ContentType.Application.Json)
            body = req
        }
        return resp
    }

    suspend fun requestCoords(req: AddressRequest) {
        val dto = jsonClient.post<CoordinatesDTO>("${getServerBaseUrl()}${CoordinatesDTO.getPath()}") {
            contentType(ContentType.Application.Json)
            body = req
        }
        MapStore.handleAction(MapCtxAction.SetLatLongFromResults(longitude = dto.longitude, latitude = dto.latitude))
    }

    suspend fun requestMapboxKey(){
        console.log("requestMapboxKey", getServerBaseUrl())
        console.log("${getServerBaseUrl()}${REST_HANDLE_MAPBOX_KEY}")
        val _key = jsonClient.get<String>("${getServerBaseUrl()}${REST_HANDLE_MAPBOX_KEY}") {
            contentType(ContentType.Application.Json)
        }
        console.log(_key)
        MapStore.handleAction(MapCtxAction.SetMapBoxApiKey(key=_key))
    }


    suspend fun requestNewJob(req: JobREQ) {
        console.log(req)
        val msg = jsonClient.post<String>("${getServerBaseUrl()}${JobREQ.getPath()}") {
            contentType(ContentType.Application.Json)
            body = req
        }
        if (msg == MSG_SERVER_CALL_WORKED) {
            console.log("requestNewJob", msg)
            console.log("it worked")
            MapStore.handleAction(MapCtxAction.ResetAfterSubmission())
        }
    }
}




