package apofnj.pages.cre

import apofnj.buildTitleTag
import kotlinx.html.classes
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.div
import react.dom.h2
import react.dom.iframe
import react.dom.p
import react.helmet.helmet

class CREVideo : RComponent<RProps, RState>() {
    private val title = "Commercial Real Estate | Video"
    override fun RBuilder.render() {
        div("PAGE CREVideo") {
            helmet(title = buildTitleTag(title))
            h2 {
                +title
            }
            p{
                +"When only the best will do, take a look at this video. We captured and produced.\n"
            }
            p{
                +"Aerial video - THIS is where drones shine. Amazing 4K footage that is rock solid and will knock your socks off! "
            }
            div("videoContainer"){
                iframe {
                    attrs.src="https://www.youtube.com/embed/OFH4Cg3vCX4"
                    attrs.width="900"
                    attrs.classes= setOf("videoIframe")
                }
            }
        }
    }
}