package apofnj.components.imageGallery

import apofnj.getPathForThumb
import apofnj.store.GalleryCTX
import apofnj.store.GalleryCTXAction
import apofnj.store.ModalCtx
import apofnj.store.Store
import kotlinx.html.Draggable
import kotlinx.html.draggable
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.onDoubleClickFunction
import kotlinx.html.js.onLoadFunction
import net.ideablender.apofnj.dto.PhotoDTO
import org.w3c.dom.Image
import org.w3c.dom.events.Event
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.*

interface ThumbImageProps : RProps {
    var photoDTO: PhotoDTO
    var position:Int
}

class ThumbImage : RComponent<ThumbImageProps, RState>() {
    fun onLoaded(event: Event){
        val img = event.target as Image
        GalleryCTX.handleAction(GalleryCTXAction.ReportThumbLoaded(props.photoDTO.fileName, img.width))
    }

    override fun RBuilder.render() {
        img(classes = "ThumbImage ${if(props.photoDTO == GalleryCTX.getState().chosenPhoto) "ThumbImageSelected" else ""} ", src = getPathForThumb(props.photoDTO)) {
            attrs.onLoadFunction = ::onLoaded
            attrs.draggable = Draggable.htmlFalse
            if(Store.isCLientPhone()){
                attrs.onClickFunction = {
                    ModalCtx.openModal {
                        child(ImageGalleryPhotoViewer::class) {
                            attrs.dtoMap = GalleryCTX.getState().photoMapLand
                            attrs.chosen = props.position
                        }
                    }
                }
            }else{
                attrs.onDoubleClickFunction = {
                    GalleryCTX.handleAction(GalleryCTXAction.HandleThumbDblClick(props.photoDTO))
                }
            }
        }
        if(Store.isCLientPhone()){
            div("ThumbImageMobileWrapper"){
                if(GalleryCTX.getState().showTitle){
                    h3{
                        +props.photoDTO.title
                    }
                    p("description"){
                        +props.photoDTO.description
                    }
                }
                if(GalleryCTX.getState().showTags){
                    div("tagWrapper"){
                        span("tagLabel"){
                            +"Tags:"
                        }
                        props.photoDTO.tags.forEachIndexed { index, dtoMin ->
                            span("tag"){
                                +dtoMin.detail
                                if(index < (props.photoDTO.tags.size -1)){
                                    +", "
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}