package apofnj.store


import apofnj.DimensionsCtx
import apofnj.helpers.Box

object ElementSizeCTX :Observed() {
    private var _footerHeight:Int = 0
    private var _navHeight:Int = 0
    private var _box: Box? = null
    private var _holderBox: Box? = null

    fun setHolderBox(b: Box){
        _holderBox = b
        invokeCbs()
    }

    fun getHolderBox() = _holderBox

    fun setBox(b: Box){
        _box = b
        invokeCbs()
    }

    fun getBox() = _box

    fun setNavHeight(d:DimensionsCtx){
        _navHeight = d.height
        invokeCbs()
    }
    fun getFooterHeight():Int = _footerHeight

    fun setFooterHeight(d:DimensionsCtx){
        _footerHeight = d.height
        invokeCbs()
    }
    fun getNavHeight():Int = _navHeight
}