package apofnj.pages

import apofnj.PHOTO_STATIC
import apofnj.endpoint
import apofnj.pojos.URL_HANDLES
import apofnj.store.*
import kotlinx.html.id
import react.*
import react.dom.*

interface SiteMapState : RState {
    var gallsLoaded:Boolean
    var tagsLoaded:Boolean
    var photosLoaded:Boolean
}

class SiteMap : RComponent<RProps, SiteMapState>() {
    override fun SiteMapState.init() {
        gallsLoaded = Store.isServiceLoaded(ServiceNames.GALLERY)
        tagsLoaded = Store.isServiceLoaded(ServiceNames.TAG)
        photosLoaded = Store.isServiceLoaded(ServiceNames.PHOTO)

    }

    override fun componentDidMount() {
        Store.registerCallback("SiteMap", ::onStoreChange)
    }

    private fun onStoreChange(){
        if(state.gallsLoaded == false || state.photosLoaded == false && state.tagsLoaded == false){
            setState {
                gallsLoaded = Store.isServiceLoaded(ServiceNames.GALLERY)
                tagsLoaded = Store.isServiceLoaded(ServiceNames.TAG)
                photosLoaded = Store.isServiceLoaded(ServiceNames.PHOTO)
            }
        }
    }

    override fun RBuilder.render() {
        div("PAGE") {
            attrs.id = "SiteMap"
            h2 {
                +"SiteMap"
            }

            if(state.photosLoaded){
                h3{
                    +"Photos"
                }
                ul{
                    PhotoService.getAll().sortedBy { it.gallery.detail }.sortedBy { it.header }.forEach {
                        li{
                            +"${it.fileName} - ${it.title} - "
                            a(href = "$endpoint/#$PHOTO_STATIC/${URL_HANDLES.ENTITY_ID}=${it.id}"){
                                +it.header
                            }
                        }
                    }
                }
            }

            if(state.gallsLoaded){
                h3{
                    +"Gallerys"
                }
                GalleryService.getAll().forEach {
                    p{
                        +it.name
                    }
                }
            }

            if(state.tagsLoaded){
                h3{
                    +"Tags"
                }
                TagService.getAll().forEach {
                    p{
                        +it.name
                    }
                }
            }
        }
    }
}