package apofnj.components

import apofnj.*
import apofnj.store.PhotoService
import apofnj.store.ServiceNames
import apofnj.store.Store
import kotlinx.html.id
import net.ideablender.apofnj.dto.PhotoDTO
import react.*
import react.dom.*
import react.helmet.helmet

interface PhotoStaticState : RState {
    var photServiceLoaded:Boolean
    var photoId:Int?
}

interface PhotoStaticProps : RProps {
}

class PhotoStatic : RComponent<RProps, PhotoStaticState>() {
    override fun PhotoStaticState.init() {
        photServiceLoaded = Store.isServiceLoaded(ServiceNames.PHOTO)
        photoId =  getDataUrlId()
    }

    override fun componentDidMount() {
        Store.registerCallback("PhotoStatic", ::onStoreChange)
    }

    private fun onStoreChange(){
        if(state.photServiceLoaded == false){
            setState {
                photServiceLoaded = Store.isServiceLoaded(ServiceNames.PHOTO)
            }
        }
    }

    override fun RBuilder.render() {
        div("PAGE") {
            attrs.id = "PhotoStatic"
            if(state.photServiceLoaded && state.photoId != null && state.photoId != undefined){
                var dto: PhotoDTO = PhotoService.findById(state.photoId!!) ?: throw Exception("Could not find a photo for ${state.photoId}")
                helmet(title = buildTitleTagForPhoto(photoTitle = dto.title, galleryTitle = dto.gallery.detail))
                h2{
                    +dto.title
                }
                img(src = getPathForImage(dto), classes = "responsiveImg"){}
                h3{
                    +dto.header
                }
                p("description"){
                    +dto.description
                }
                p{
                    label{
                        +"Gallery:"
                    }
                    +dto.gallery.detail
                }
                p{
                    label{
                        +"Date Taken:"
                    }
                    +dto.dateTaken.toDateTimeFormatted()
                }
                p{
                    label{
                        +"Tags:"
                    }
                    dto.tags.forEachIndexed { index, dtoMin ->
                        span("tag"){
                            +dtoMin.detail
                            if(index < (dto.tags.size -1)){
                                +", "
                            }
                        }

                    }
                }
            }else{
                h2 {
                    +"Photo Static"
                }
            }
        }
    }
}