package apofnj.bindings.reactmapgldraw

import apofnj.bindings.reactmapboxgl.FeatureProps

typealias ClickEvent = BasePointerEvent
typealias TentativeFeature = FeatureWithProps<dynamic /* Point | LineString | Polygon | MultiPoint | MultiLineString | MultiPolygon */, FeatureProps>

typealias MyCoords = Array<Double>



/*
@Serializable
data class Coords(
    val longitude:Double,
    val latitude:Double
)
*/

enum class MapMode( val mode:GeoJsonEditMode?){
    DRAW_POLYGON(DrawPolygonMode()),
    EDIT_MAP(EditingMode()),
    FEATURE_COMPLETE(null),
}

class MyGeometry : Geometry {
    override val coordinates: Array<Array<Array<Double>>> = arrayOf()

    override val type: String = ""
}


fun String?.isNullUndefinedOrEmpty():Boolean{
    return (this == null || this == undefined || this.isEmpty())
}

