package apofnj.pages

import apofnj.buildTitleTag
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.div
import react.dom.h2
import react.helmet.helmet


//https://github.com/uber/nebula.gl/blob/master/examples/react-map-gl-draw/app.js
class Mapping : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        div("PAGE Mapping") {
            helmet(title = buildTitleTag("Mapping"))
            h2 {
                +"Mapping"
            }
        }
    }
}