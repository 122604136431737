package apofnj.pages


import apofnj.*
import apofnj.helpers.SizeAwarePref
import apofnj.store.Store
import kotlinx.html.id
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.*
import react.helmet.helmet
import react.router.dom.routeLink


class Home : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        div {
            attrs.id = "HOME"
            helmet(title = buildTitleTag("Home"))
            child(HeroImage::class){
                attrs{
                    pref = SizeAwarePref.NOFOOTER
                    pageTag = "HeroImage"
                }
            }
            div("midRow"){
                div("STORY1 story"){
                    div("imgWrapper"){
                        img(src = "/img/Do_You_Need_Great_Aerials_Aerial-Photo-NJ-00013 copy.jpg", classes = "responsiveImg"){}
                    }
                    h3{
                        +"Why Do You Need Great Aerials???"
                    }

                    span("subHeading"){
                        +"The quality of your OM’s go a long way towards establishing your reputation in the industry, so don’t let outdated, poor quality, or otherwise inferior photos damage it."
                    }

                    span("text"){
                        +"See for yourself how we help you set yourself apart from your competitors with outstanding photography!"
                    }
                    span("learnMore"){
                        routeLink(to = NAV_KEY_COMMERCIAL_REAL_ESTATE) {
                            +"Learn More"
                            i("fas fa-arrow-right"){}
                        }
                    }
                }
                div("STORY2 story"){
                    div("imgWrapper"){
                        img(src = "/img/aerial_landscape_5.jpg", classes = "responsiveImg"){}
                    }
                    h3{
                        +"What’s Better For Your Job - Drones, or Traditional Aircraft Flown Aerials???"
                    }

                    span("subHeading"){
                        +"They both have benefits..."
                    }

                    span("text"){
                        +"Read the Pros and Cons of each photography platform, including cost estimates, and why you’ll sometimes want both!"
                    }


                    span("learnMore"){
                        routeLink(to = NAV_KEY_CRE_DRONE) {
                            +"Learn More"
                            i("fas fa-arrow-right"){}
                        }
                    }
                }

                div("STORY3 story"){
                    div("imgWrapper"){
                        img(src = "/img/arch_land_1.jpg", classes = "responsiveImg"){}
                    }
                    h3{
                        +"Why You Want Us As A Valued Team Member…"
                    }
                    span("subHeading"){
                        +"It’s not just the quality of our photographs that have clients coming back to us year after year since 1999."
                    }

                    span("text"){
                        +"What makes us stand out even more is our flexible scheduling with built in bad weather day backups, discreet service that assures confidentiality, and a team first attitude that you rarely find these days. Find out how we do it…"

                    }

                    span("learnMore"){
                        routeLink(to = NAV_KEY_CRE_ARCH) {
                            +"Learn More"
                            i("fas fa-arrow-right"){}
                        }
                    }
                }
            }
        }
    }
}