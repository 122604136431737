package apofnj.store

import kotlinx.browser.document
import kotlinx.browser.window
import react.RBuilder

object ModalCtx : Observed() {
    private var _open = false
    private var _body: (RBuilder.() -> Unit)? = null

    fun openModal(body:RBuilder.() -> Unit){
        _open = true
        _body = body

        invokeCbs()
        hideScroll()
    }

    private fun hideScroll(){
        document.body!!.style.overflowY = "hidden"
        document.body!!.style.overflowX = "hidden"
    }

    private fun showScroll(){
        document.body!!.style.overflowY = "auto"
        document.body!!.style.overflowX = "auto"
    }

    fun getBody() = _body!!

    fun isOpen() = _open

    fun closeModal(){
        _open = false
        _body = null
        showScroll()
        invokeCbs()
    }
}