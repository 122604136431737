package apofnj.pages


import apofnj.buildTitleTag
import apofnj.components.mapping.MapControls
import apofnj.components.mapping.MapForm
import apofnj.components.mapping.MapHolder
import apofnj.helpers.BoundaryAwareSupport
import apofnj.helpers.Box
import apofnj.helpers.HtmlElementNotFoundForIdException
import apofnj.helpers.ID_FOOTER
import apofnj.store.ModalCtx
import apofnj.store.Store
import apofnj.store.mapping.MapCtxAction
import apofnj.store.mapping.MapStore
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.css.*
import kotlinx.html.ButtonType
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import org.w3c.dom.Element
import org.w3c.dom.events.Event
import react.*
import react.dom.*
import react.helmet.helmet
import styled.css
import styled.styledDiv
import kotlin.js.Date


class SiteLocator : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        div() {
            attrs.id = "SITELOCATOR"
            helmet(title = buildTitleTag("Site Locator"))
            div("left"){
                child(mapWrapper)
            }
            div("right"){
              child(MapControls::class){}
              child(MapForm::class){}
            }
        }
    }
}

class SiteLocatorThankYou : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        div {
            attrs.id = "SiteLocatorThankYou"
            h3{
                +"We Received Your Request"
            }
            p{
                +"Someone will contact you about your quote shortly.  Feel free to submit another request by clicking the close button and using the form again."
            }
            div("btnWrapper"){
                button(classes = "pure-button button-primary", type = ButtonType.button){
                    +"Close"
                    attrs.onClickFunction ={
                        ModalCtx.closeModal()
                    }
                }
            }
        }
    }
}

class ProcessingRequest : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        div {
            attrs.id = "ProcessingRequest"
            div("headerWrapper"){
                div("loader"){}
                h3{
                    +"We are working on your request, please wait..."
                }
            }
        }
    }
}




/* https://stackoverflow.com/questions/43817118/how-to-get-the-width-of-a-react-element */
val mapWrapper = functionalComponent<RProps> {
    var wrapperWref: RMutableRef<Element?> = useRef(null)
    val (boundary, setBoundary) = useState(Box(0,0))

    useEffect {
        if(boundary.width == 0){
            updateBoundary(boundary, setBoundary)
            window.addEventListener("resize", {
                updateBoundary(boundary, setBoundary)
            })
        }
    }
    /*
    * Using ref here does not work the ref disappears on resize.
    * */
    div{
        attrs.id = ID_MAP_WRAPPER
        child(MapHolder::class){}
    }
}

fun updateBoundary(boundary: Box, setBoundary : (Box)->Unit){
    val mapWrapperEle = document.getElementById(ID_MAP_WRAPPER) ?: throw HtmlElementNotFoundForIdException("footloose")
    var b = Box(width = mapWrapperEle.clientWidth, height = BoundaryAwareSupport.getAvailableDimmensNoFooter().height)
    if(Store.isCLientPhone()){
        b = b.copy(height = (b.height * .5).toInt())
    }
    MapStore.handleAction(MapCtxAction.SetDimmens(b))
    setBoundary(b)
}

val ID_MAP_WRAPPER = "MAP_WRAPPER"
