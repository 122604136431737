package apofnj.components.mapping

import apofnj.bindings.reactmapgldraw.MapMode
import apofnj.store.mapping.MapCtxAction
import apofnj.store.mapping.MapCtxState
import apofnj.store.mapping.MapStore
import apofnj.store.mapping.MapStyle
import kotlinx.html.ButtonType
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*

interface MapControlsState : RState {
    var mapCtx: MapCtxState
}

interface MapControlsProps : RProps {
}

class MapControls : RComponent<RProps, MapControlsState>() {
    override fun MapControlsState.init() {
        mapCtx = MapStore.getCurrentState()
    }

    override fun componentDidMount() {
        MapStore.registerCallback("MapControls", ::onMapStoreChange)
    }

    private fun onMapStoreChange() {
        setState {
            mapCtx = MapStore.getCurrentState()
        }
    }

    override fun RBuilder.render() {
        div() {
            attrs.id = "MapControls"
            div("changeMapStyle"){
                div("btnWrapper"){
                    button(classes = "pure-button button-primary", type = ButtonType.button) {
                        attrs.disabled = state.mapCtx.mapStyle == MapStyle.SATELLITE
                        +"Satellite"
                        attrs.onClickFunction = {
                            MapStore.handleAction(MapCtxAction.ChangeStyle(MapStyle.SATELLITE))
                        }
                    }
                }
                div("btnWrapper"){
                    button(classes = "pure-button button-primary", type = ButtonType.button){
                        attrs.disabled = state.mapCtx.mapStyle == MapStyle.STREETS
                        +"Streets"
                        attrs.onClickFunction = {
                            MapStore.handleAction(MapCtxAction.ChangeStyle(MapStyle.STREETS))
                        }
                    }
                }
                div("btnWrapper"){
                    button(classes = "pure-button button-primary", type = ButtonType.button){
                        attrs.disabled = state.mapCtx.mapStyle == MapStyle.SATELLITE_STREETS
                        +"Sat/Streets"
                        attrs.onClickFunction = {
                            MapStore.handleAction(MapCtxAction.ChangeStyle(MapStyle.SATELLITE_STREETS))
                        }
                    }
                }
            }

            div("instructions"){
                when(state.mapCtx.modeCtx){
                    MapMode.DRAW_POLYGON -> {
                        p{
                            +"This is where the magic starts! Outline your listing by clicking on you property boundaries. When you’re done, double click the last point, and the shading will change from red to blue. Let us know any specific needs in the description box, and fill out the contact info. You’ll receive a confirmation email, and we’ll get started working on your estimate right away!"
                        }
                    }
                    MapMode.EDIT_MAP -> {
                        p{
                            +"To change your shape, double click on it and move the shape handles, click the "
                            span("bold text-success"){
                                +"save"
                            }
                            + " button when done."
                        }
                    }
                    MapMode.FEATURE_COMPLETE -> {
                        p{
                            +"Awesome, looks like your site is marked, to make changes click the "
                            span("bold text-primary"){
                                +"edit"
                            }
                            + " button."
                        }
                    }
                }
            }
        }
    }
}