package apofnj.pages.cre

import apofnj.buildTitleTag
import apofnj.components.imageGallery.ImageGalleryWrapper
import net.ideablender.apofnj.pojo.GalleryName
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.div
import react.dom.h2
import react.dom.p
import react.helmet.helmet

class CREAerial : RComponent<RProps, RState>() {
    private val title = "Commercial Real Estate | Aerial"
    override fun RBuilder.render() {
        div("PAGE CREAerial") {
            helmet(title = buildTitleTag(title))
            h2 {
                +title
            }
            p{
                +"Traditional aircraft flown aerial photography - done right!"
            }
            p{
                +"This is where we got our start back in 1999, and it is still our bread and butter. And while the amateur drone providers have cut into our business, most professionals in commercial real estate understand that saving a few bucks isn’t worth the aggravation of trying to justify lousy photos to your clients."
            }
            p{
                +"Don’t get me wrong, drones have a valuable place in our toolbox, especially when it comes to video, but nothing compares to a well flown set of aerial photos from an aircraft. I go into more detail about this on the Drone page. In the meantime, look at these aerial photos and let me know if you’d be proud to put them into your OM!"
            }
            child(ImageGalleryWrapper::class) {
                attrs.galleryName = GalleryName.CRE_AERIAL
            }
        }
    }
}