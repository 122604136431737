
package apofnj.bindings


import react.RBuilder
import react.RClass
import react.RProps


@JsModule("react-draggable")
@JsNonModule
@JsName("Draggable")
external val ReactDraggable : RClass<ReactDraggableProps>

interface ReactDraggableProps:RProps{
    var axis:String
    var body:(RBuilder.() -> Unit)
    var bounds: Boundary
}

data class Boundary(val left:Int = 0, val top: Int = 0, val right:Int = 0, val bottom:Int = 0)
