package apofnj.store.mapping

import apofnj.bindings.reactmapboxgl.ViewportProps
import apofnj.bindings.reactmapgldraw.DrawPolygonMode
import apofnj.bindings.reactmapgldraw.Feature
import apofnj.bindings.reactmapgldraw.MapMode
import apofnj.helpers.Box
import apofnj.pages.SiteLocatorThankYou
import apofnj.store.ModalCtx
import apofnj.store.Observed
import net.ideablender.apofnj.req.JobREQ
import net.ideablender.apofnj.statics.LIST_STATES
import kotlin.js.Date

object MapStore: Observed() {
    private var state: MapCtxState = MapCtxState()
    fun getCurrentState(): MapCtxState = state

    fun reset() {
        handleAction(MapCtxAction.ResetState())
    }

    fun handleAction(action: MapCtxAction) {
        when(action){
            is MapCtxAction.ResetState -> state = MapCtxState()
            is MapCtxAction.ChangeStyle -> state = state.copy(mapStyle = action.style)
            is MapCtxAction.ClearFeatures -> state = state.copy(features = emptyArray(), modeCtx = MapMode.DRAW_POLYGON)
            is MapCtxAction.SetFeaturesComplete -> state = state.copy(modeCtx = MapMode.FEATURE_COMPLETE)
            is MapCtxAction.SetModeToEdit -> state = state.copy(modeCtx = MapMode.EDIT_MAP)
            is MapCtxAction.ChangeViewport -> {
                state = state.copy(viewport = state.viewport.copy(
                    height = action.vpProps.height,
                    width = action.vpProps.width,
                    latitude = action.vpProps.latitude,
                    longitude = action.vpProps.longitude,
                    zoom = action.vpProps.zoom
                ))
            }
            is MapCtxAction.ChangeFeatures -> {
                state = if(state.features.isEmpty()){
                    state.copy(features = action.features, modeCtx = MapMode.FEATURE_COMPLETE)
                }else{
                    state.copy(features =action.features)
                }
            }
            is MapCtxAction.SetDimmens -> {
                state = state.copy(viewport = state.viewport.copy(
                    height = action.dimmens.height,
                    width = action.dimmens.width,
                ))
            }
            is MapCtxAction.SetLatLong -> {
                state = state.copy(viewport = state.viewport.copy(
                    latitude = action.latitude,
                    longitude = action.longitude,
                ))
            }
            is MapCtxAction.SetLatLongFromResults -> {
                state = state.copy(viewport = state.viewport.copy(
                    latitude = action.latitude,
                    longitude = action.longitude,
                    zoom = MAPPING_DEF_ZOOM_FROM_SERVER
                ))
            }
            is MapCtxAction.UpdateJobReq -> {
                state = state.copy(jobREQ = action.req)
            }
            is MapCtxAction.ResetAfterSubmission -> {
                ModalCtx.openModal {
                    child(SiteLocatorThankYou::class){}
                }
                state = state.copy(features = emptyArray(), modeCtx = MapMode.DRAW_POLYGON, jobREQ = state.jobREQ.copy(siteName = "", siteStreet = "", siteCity = "", siteState = "", siteCoords = mutableListOf(), siteDescription = ""))
            }
            is MapCtxAction.SetMapBoxApiKey -> {
                state = state.copy(mapboxApiKey = action.key)
            }
        }
        invokeCbs()
    }
}

/*
* leaving this as an example of how to build a js pojo
* fun getFS() = js("({stroke:'#0000ff', fill:'#ffffff', fillOpacity: 0.2})")
* */


fun getFS(mode: MapMode):FeatureStyleDefault{
    val blue = "#007bff"
    val red = "#dc3545"
    val teal = "#17a2b8"
    return when(mode){
        MapMode.DRAW_POLYGON -> FeatureStyleDefault(stroke = red, fill = red)
        MapMode.EDIT_MAP -> FeatureStyleDefault(stroke = blue, fill = blue)
        MapMode.FEATURE_COMPLETE -> FeatureStyleDefault(stroke = teal, fill = teal)
    }
}

class FeatureStyleDefault(
    val stroke:String = "#0000ff",
    val fill:String = "#0000ff",
    val fillOpacity:Number = 0.5)

enum class MapStyle(val style: String) {
    STREETS("mapbox://styles/mapbox/streets-v11"),
    SATELLITE("mapbox://styles/mapbox/satellite-v9"),
    SATELLITE_STREETS("mapbox://styles/mapbox/satellite-streets-v9")
}
data class MapCtxState(
    val viewport: Viewport = Viewport(
        latitude = MAPPING_DEF_LATITUDE,
        longitude = MAPPING_DEF_LONGITUDE,
        zoom = MAPPING_DEF_ZOOM,
        height = MAPPING_DEF_HEIGHT,
        width = MAPPING_DEF_WIDTH,
        lastUpdate = Date().getTime()
    ),
    val mapboxApiKey:String?= null,
    val modeCtx: MapMode = MapMode.DRAW_POLYGON,
    var features: Array<Feature> = emptyArray(),
    val mapStyle:MapStyle = MapStyle.SATELLITE_STREETS,
    //val jobREQ: JobREQ = JobREQ(siteState = LIST_STATES.sorted().first()) //setting the state for dropdowns
    val jobREQ: JobREQ = JobREQ() //setting the state for dropdowns
)

sealed class MapCtxAction  {
    class ResetState : MapCtxAction()
    class SetMapBoxApiKey(val key: String) : MapCtxAction()
    class ChangeViewport(val vpProps: ViewportProps) : MapCtxAction()
    class ChangeFeatures(val features: Array<Feature>) : MapCtxAction()
    class SetModeToEdit : MapCtxAction()
    class ClearFeatures : MapCtxAction()
    class SetFeaturesComplete : MapCtxAction()
    class UpdateJobReq(val req: JobREQ) : MapCtxAction()
    class ChangeStyle(val style: MapStyle) : MapCtxAction()
    class SetDimmens(val dimmens: Box) : MapCtxAction()
    class SetLatLong(val latitude: Double, val longitude:Double) : MapCtxAction()
    class SetLatLongFromResults(val latitude: Double, val longitude:Double) : MapCtxAction()
    class ResetAfterSubmission : MapCtxAction()
}


val MAPPING_DEF_LONGITUDE = -77.036873
val MAPPING_DEF_LATITUDE = 38.907192
val MAPPING_DEF_HEIGHT = 600
val MAPPING_DEF_WIDTH = 600
val MAPPING_DEF_ZOOM = 15
val MAPPING_DEF_ZOOM_FROM_SERVER = 19


data class Viewport(
    override var width: Number,
    override var height: Number,
    override var latitude: Double,
    override var longitude: Double,
    override var zoom: Number,
    override var bearing: Number? = null,
    override var pitch: Number? = null,
    override var altitude: Number? = null,
    override var maxZoom: Number? = null,
    override var minZoom: Number? = null,
    override var maxPitch: Number? = null,
    override var minPitch: Number? = null,
    override var transitionDuration: dynamic = null,
    override var transitionInterpolator: dynamic = null,
    override var transitionInterruption: dynamic = null,
    override var transitionEasing: dynamic = null,
    var lastUpdate: Double,
    var dirty: Boolean = false
) : ViewportProps


