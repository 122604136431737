package apofnj.components.mapping

import apofnj.bindings.Position
import apofnj.bindings.getCurrentPosition
import apofnj.bindings.reactmapgldraw.MapMode
import apofnj.bindings.reactmapgldraw.isNullUndefinedOrEmpty
import apofnj.bindings.reactselect.ReactSelect
import apofnj.ReactSelectOption
import apofnj.crScrope
import apofnj.pages.ProcessingRequest
import apofnj.pages.SiteLocatorThankYou
import apofnj.store.ModalCtx
import apofnj.store.ServerClient
import apofnj.store.Store
import apofnj.store.mapping.MapCtxAction
import apofnj.store.mapping.MapCtxState
import apofnj.store.mapping.MapStore
import apofnj.store.mapping.MapStyle
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTextAreaElement
import org.w3c.dom.events.Event
import react.*
import react.dom.*
import kotlinx.coroutines.launch
import kotlinx.html.ButtonType
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.dto.CoordinatesDTO
import net.ideablender.apofnj.req.AddressRequest
import net.ideablender.apofnj.statics.LIST_STATES

enum class FormDataType {
    STRING,
    DATE,
    INT
}

data class FormData(val label: String, val value: dynamic, val param: String, val formDataType: FormDataType = FormDataType.STRING) {
    companion object {
        fun eventToFormData(event: Event): FormData {
            val target = event.target as HTMLInputElement
            return FormData(param = target.name, value = target.value, label = target.value)
        }
    }
}

interface MapFormState : RState {
    var mapCtx: MapCtxState
}

class MapForm : RComponent<RProps, MapFormState>() {
    override fun MapFormState.init() {
        mapCtx = MapStore.getCurrentState()
    }

    override fun componentDidMount() {
        getCurrentPosition(::useLocation)
        MapStore.registerCallback("MapForm", ::onMapStoreChange)
    }

    fun getCoords(ar:Array<Array<Array<Double>>> ):List<CoordinatesDTO>{
        val retList:MutableList<CoordinatesDTO> = mutableListOf()
        ar.first().forEach {
            retList.add(CoordinatesDTO(longitude = it[0], latitude = it[1]))
        }
        return retList
    }

    fun onMapStoreChange(){
        setState {
            mapCtx = MapStore.getCurrentState()
        }
    }

    private fun useLocation(err: dynamic, position: Position) {
        if (position.coords.longitude != null && position.coords.latitude != null) {
            MapStore.handleAction(MapCtxAction.SetLatLong(latitude = position.coords.latitude!!.toDouble(), longitude = position.coords.longitude!!.toDouble()))
        } else {
            console.warn("Could not get the lat and long for the client")
        }
    }



    private fun isSubmitDisabled():Boolean{
        val req = state.mapCtx.jobREQ
        if(req.contactNameFirst.isNullUndefinedOrEmpty()) return true
        if(req.contactNameLast.isNullUndefinedOrEmpty()) return true
        if(req.siteStreet.isNullUndefinedOrEmpty()) return true
        if(req.siteState.isNullUndefinedOrEmpty()) return true
        if(req.siteCity.isNullUndefinedOrEmpty()) return true
        if(req.contactEmail.isNullUndefinedOrEmpty()) return true
        if(state.mapCtx.features.isEmpty()) return true
        return false
    }

    private fun onInputChange(fd: FormData) {
        var newReq = state.mapCtx.jobREQ
        when (fd.param) {
            "companyName" -> newReq = newReq.copy(companyName = fd.value as String )
            "siteStreet" -> newReq = newReq.copy(siteStreet = fd.value as String )
            "siteCity" -> newReq = newReq.copy(siteCity = fd.value as String )
            "siteState" -> newReq = newReq.copy(siteState = fd.value as String )
            "contactEmail" -> newReq = newReq.copy(contactEmail = fd.value as String )
            "contactNameFirst" -> newReq = newReq.copy(contactNameFirst = fd.value as String )
            "contactNameLast" -> newReq = newReq.copy(contactNameLast = fd.value as String )
            "siteName" -> newReq = newReq.copy(siteName = fd.value as String )
            "contactPhone" -> newReq = newReq.copy(contactPhone = fd.value as String )
            "siteDescription" -> newReq = newReq.copy(siteDescription = fd.value as String )
        }
        MapStore.handleAction(MapCtxAction.UpdateJobReq(newReq))
    }

    private fun handleSelect(option: ReactSelectOption){
        console.log(option)
    }

    private fun RBuilder.markRequired(){
        div("formRequired"){
            +"*"
        }
    }

    fun isFindAddressDisabled():Boolean{
        val req = state.mapCtx.jobREQ
        if(req.siteCity.isNullUndefinedOrEmpty()) return true
        if(req.siteState.isNullUndefinedOrEmpty()) return true
        if(req.siteStreet.isNullUndefinedOrEmpty()) return true

        return false
    }

    fun RBuilder.getNameInputs(){
        val req = state.mapCtx.jobREQ
       if(Store.isPhone()){
           div("pure-control-group"){
               label {
                   markRequired()
                   +"First Name:"
               }
               input(type = InputType.text, name = "contactNameFirst") {
                   attrs.value = req.contactNameFirst ?: ""
                   attrs.onChangeFunction = {
                       onInputChange(FormData.eventToFormData(it))
                   }
               }
           }
           div("pure-control-group"){
               label {
                   markRequired()
                   +"Last Name:"
               }
               input(type = InputType.text, name = "contactNameLast") {
                   attrs.value = req.contactNameLast ?: ""
                   attrs.onChangeFunction = {
                       onInputChange(FormData.eventToFormData(it))
                   }
               }
           }
       }else{
           div("pure-control-group"){
               label {
                   markRequired()
                   +"First Name:"
               }
               input(type = InputType.text, name = "contactNameFirst") {
                   attrs.value = req.contactNameFirst ?: ""
                   attrs.onChangeFunction = {
                       onInputChange(FormData.eventToFormData(it))
                   }
               }
               label("marginLeft") {
                   markRequired()
                   +"Last Name:"
               }
               input(type = InputType.text, name = "contactNameLast") {
                   attrs.value = req.contactNameLast ?: ""
                   attrs.onChangeFunction = {
                       onInputChange(FormData.eventToFormData(it))
                   }
               }
           }
       }
    }

    override fun RBuilder.render() {
        val req = state.mapCtx.jobREQ
        div{
            attrs.id = "MapForm"

           div("card addBottomMargin"){
                div("cardHeader"){
                    +"Address Lookup"
                }
               div("cardBody "){
                   form(classes = "pure-form pure-form-aligned address"){
                       div("pure-control-group"){
                           label {
                               markRequired()
                               +"Street:"
                           }
                           input(type = InputType.text, name = "siteStreet") {
                               attrs.value = req.siteStreet ?: ""
                               attrs.onChangeFunction = {
                                   onInputChange(FormData.eventToFormData(it))
                               }
                           }
                       }
                       div("cityState"){
                           div("pure-control-group city"){
                               label {
                                   markRequired()
                                   +"City:"
                               }
                               input(type = InputType.text, name = "siteCity") {
                                   attrs.value = req.siteCity ?: ""
                                   attrs.onChangeFunction = {
                                       onInputChange(FormData.eventToFormData(it))
                                   }
                               }
                           }
                           div("pure-control-group"){
                               val selected = if(state.mapCtx.jobREQ.siteState.isNullUndefinedOrEmpty()){
                                   ReactSelectOption(value = "--", label = "--")
                               }else{
                                   ReactSelectOption(value = state.mapCtx.jobREQ.siteState!!, label = state.mapCtx.jobREQ.siteState!!)
                               }
                               val stateOptions = mutableListOf<ReactSelectOption>()
                               LIST_STATES.sorted().forEach {
                                   stateOptions.add(ReactSelectOption(it,it))
                               }
                               stateOptions.add(0, ReactSelectOption("--","--"))

                               label {
                                   markRequired()
                                   +"State:"
                               }
                               ReactSelect{
                                   attrs{
                                       value = selected
                                       onChange = {
                                           if(it.value != "--"){
                                               onInputChange(
                                                   FormData(
                                                       label = it.label,
                                                       value = it.value,
                                                       param = "siteState"
                                                   ))
                                           }
                                       }
                                       options = stateOptions.toTypedArray()
                                       className = "ReactSelect"
                                       classNamePrefix = "RSMapFormSt"
                                   }
                               }
                           }
                       }
                       button(classes = "pure-button button-primary", type = ButtonType.button){
                           +"Find Address"
                           val addressRequest = AddressRequest("${req.siteStreet}, ${req.siteCity}, ${req.siteState}")
                           attrs.disabled = isFindAddressDisabled()
                           attrs.onClickFunction = {
                               crScrope.launch {
                                   ServerClient.requestCoords(addressRequest)
                                   ServerClient
                               }
                           }
                       }
                   }
               }
           }
            div("card  addBottomMargin"){
                div("cardHeader"){
                    +"Site Info"
                }
                div("cardBody "){
                    form(classes = "pure-form pure-form-aligned siteInfo"){
                        div("pure-control-group"){
                            label {
                                +"Name:"
                            }
                            input(type = InputType.text, name = "siteName") {
                                attrs.value = req.siteName ?: ""
                                attrs.onChangeFunction = {
                                    onInputChange(FormData.eventToFormData(it))
                                }
                            }
                        }
                        div("pure-control-group"){
                            label {
                                +"Description:"
                            }
                            textArea(classes = "") {
                                attrs.value = req.siteDescription ?: ""
                                attrs.name = "siteDescription"
                                attrs.onChangeFunction = {
                                    val target = it.target as HTMLTextAreaElement
                                    onInputChange(FormData(label = "siteDescription", value = target.value.take(250), param = "siteDescription"))
                                }
                            }
                        }
                    }
                }
            }


            div("card contactInfo addBottomMargin"){
                div("cardHeader"){
                    +"Contact Info"
                }
                div("cardBody "){
                    form(classes = "pure-form pure-form-aligned contactInfo"){
                        getNameInputs()
                        div("pure-control-group"){
                            label {
                                +"Company:"
                            }
                            input(type = InputType.text, name = "companyName") {
                                attrs.value = req.companyName ?: ""
                                attrs.onChangeFunction = {
                                    onInputChange(FormData.eventToFormData(it))
                                }
                            }
                        }
                        div("pure-control-group"){
                            label {
                                markRequired()
                                +"Email:"
                            }
                            input(type = InputType.text, name = "contactEmail") {
                                attrs.value = req.contactEmail ?: ""
                                attrs.onChangeFunction = {
                                    onInputChange(FormData.eventToFormData(it))
                                }
                            }
                        }
                        div("pure-control-group"){
                            label {
                                +"Phone:"
                            }
                            input(type = InputType.text, name = "contactPhone") {
                                attrs.value = req.contactPhone ?: ""
                                attrs.onChangeFunction = {
                                    onInputChange(FormData.eventToFormData(it))
                                }
                            }
                        }
                    }
                }

            }

            div("changeMapMode"){
                div("btnWrapper"){
                    when(state.mapCtx.modeCtx){
                        MapMode.EDIT_MAP -> {
                            button(classes = "pure-button button-primary", type = ButtonType.button) {
                                attrs.onClickFunction ={
                                    MapStore.handleAction(MapCtxAction.SetFeaturesComplete())
                                }
                                +"Save Changes"
                            }
                        }
                        MapMode.DRAW_POLYGON -> {
                            button(classes = "pure-button button-primary", type = ButtonType.button) {
                                attrs.disabled = state.mapCtx.modeCtx != MapMode.FEATURE_COMPLETE
                                attrs.onClickFunction ={
                                    MapStore.handleAction(MapCtxAction.SetModeToEdit())
                                }
                                +"Edit"
                            }
                        }
                        MapMode.FEATURE_COMPLETE -> {
                            button(classes = "pure-button button-primary ", type = ButtonType.button) {
                                attrs.disabled = state.mapCtx.modeCtx != MapMode.FEATURE_COMPLETE
                                attrs.onClickFunction ={
                                    MapStore.handleAction(MapCtxAction.SetModeToEdit())
                                }
                                +"Edit"
                            }
                        }
                    }
                }
                div("btnWrapper"){
                    button(classes = "pure-button button-error", type = ButtonType.button) {
                        attrs.disabled = state.mapCtx.features.isEmpty()
                        attrs.onClickFunction ={
                            MapStore.handleAction(MapCtxAction.ClearFeatures())
                        }
                        +"Start Over"
                    }
                }
                div("btnWrapper"){
                    button(classes = "pure-button button-success"){
                        attrs.disabled = isSubmitDisabled()
                        attrs.onClickFunction = {
                            ModalCtx.openModal {
                                child(ProcessingRequest::class){}
                            }
                            val req = state.mapCtx.jobREQ
                            req.siteCoords.clear()
                            req.siteCoords.addAll(getCoords(MapStore.getCurrentState().features.first().geometry.coordinates))
                            crScrope.launch {
                                ServerClient.requestNewJob(req)
                            }
                        }
                        +"Send Request"
                    }
                }
            }
        }
    }
}