package apofnj
val NAV_KEY_HOME = "/home"
val NAV_KEY_SITE_MAP = "/siteMap"
val PHOTO_STATIC = "/photoStatic"
val NAV_KEY_IMAGE_GALLERY = "/imageGallery"
val NAV_KEY_CONTACT = "/contact"
val NAV_KEY_ABOUT_US = "/aboutUs"
val NAV_KEY_PRIVACY_POLICY = "/privacyPolicy"
val NAV_KEY_SITE_LOCATOR = "/siteLocator"
val NAV_KEY_TESTIMONIALS = "/testimonials"
val NAV_KEY_MAPPING = "/mapping"
val NAV_KEY_COMMERCIAL_REAL_ESTATE = "/commercialRealEstate"
val NAV_KEY_CRE_AERIAL = "$NAV_KEY_COMMERCIAL_REAL_ESTATE/aerial"
val NAV_KEY_CRE_ARCH = "$NAV_KEY_COMMERCIAL_REAL_ESTATE/architectectural"
val NAV_KEY_CRE_MATTER_PORT = "$NAV_KEY_COMMERCIAL_REAL_ESTATE/matterPort"
val NAV_KEY_CRE_DRONE = "${NAV_KEY_COMMERCIAL_REAL_ESTATE}/drone"
val NAV_KEY_CRE_INTERIOR = "${NAV_KEY_COMMERCIAL_REAL_ESTATE}/interior"
val NAV_KEY_CRE_VIDEO = "$NAV_KEY_COMMERCIAL_REAL_ESTATE/video"
val NAV_KEY_CONSTRUCTION_PROGRESS = "/constructionProgress"
val NAV_KEY_CP_CONSTRUCTION_CAM = "$NAV_KEY_CONSTRUCTION_PROGRESS/constructionCam"
val NAV_KEY_CP_AERIAL = "$NAV_KEY_CONSTRUCTION_PROGRESS/aerial"
val NAV_KEY_CP_GROUND = "$NAV_KEY_CONSTRUCTION_PROGRESS/ground"
val NAV_KEY_CP_MATTER_PORT = "$NAV_KEY_CONSTRUCTION_PROGRESS/matterPort"
val NAV_KEY_CP_VIDEO = "$NAV_KEY_CONSTRUCTION_PROGRESS/video"
val META_TITLE_ROOT = "APOFNJ"