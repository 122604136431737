package apofnj.pages.cre

import apofnj.buildTitleTag
import apofnj.components.imageGallery.ImageGalleryWrapper
import net.ideablender.apofnj.pojo.GalleryName
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.*
import react.helmet.helmet

class CREArchitectural : RComponent<RProps, RState>() {
    private val title = "Commercial Real Estate | Architectural"
    override fun RBuilder.render() {
        div("PAGE CREArchitectural") {
            helmet(title = buildTitleTag(title))
            h2 {
                +title
            }
            p("ulHead"){
                +"What makes an excellent photographer of commercial real estate? Over the years we’ve fined tuned our architectural photography offerings and we’re now at the point where we know we can exceed your expectations as a valued team member. How? Simple – we put ourselves in your shoes, and figured out what’s best for you. "
            }
            ul{

                li{
                    +"We offer flexible scheduling, with bad weather day backups – No more long weather delays!"
                }
                li{
                    +"We’re discreet. The tenants will never know that the building is for sale – No more worries about the tenants contacting ownership"
                }
                li{
                    +"We include drone photography, at no extra charge, on every exterior photo shoot – No additional charges or surprises on your invoice"
                }
                li{
                    +"We include HDR photography, at no extra charge, on every interior shoot – You get great HDR quality at no additional cost!"
                }
                li{
                    +"We delivery your proofs fast, and you have unlimited selections – You’re free to pick as many photos as you want, and there are no usage restrictions on your photos except you cannot resell them"
                }
                li{
                    +"We process your photos fast, and get them online in your password protected gallery that stays online FOREVER – No more worries about misplaced photos!"
                }
            }
            child(ImageGalleryWrapper::class) {
                attrs.galleryName = GalleryName.CRE_ARCH
            }
        }
    }
}