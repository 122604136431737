package apofnj.store

import net.ideablender.apofnj.dto.PhotoDTO


sealed class GalleryCTXAction{
    class ReportThumbLoaded(val fileName: String, val width:Int) : GalleryCTXAction()
    class HandleThumbDblClick(val dto: PhotoDTO) : GalleryCTXAction()
    class HandleShowTagsClicked() : GalleryCTXAction()
    class HandleShowTitleClicked() : GalleryCTXAction()
    class HandleAddWantedTag(val tag:String) : GalleryCTXAction()
    class HandleRemoveWantedTag(val tag:String) : GalleryCTXAction()
    class HandleBuildingOfMaps(val photos: List<PhotoDTO>) : GalleryCTXAction()
}

data class GalleryCTXState(
    val chosenPhoto:PhotoDTO? = null,
    var showTags: Boolean = true,
    var showTitle: Boolean = true,
    val thumbWidths:MutableMap<String, Int> = mutableMapOf(),
    val thumbWidthsMaster:MutableMap<String, Int> = mutableMapOf(),
    val thumbParentDtos:MutableMap<String, PhotoDTO> = mutableMapOf(),
    var wantedTags:Set<String> = setOf(),
    var unwantedTags:Set<String> = setOf(),
    val photoMapPort:Map<Int, PhotoDTO> = mapOf(),
    val photoMapLand:Map<Int, PhotoDTO> = mapOf(),
    val photoMapsFinished:Boolean = false
)

object GalleryCTX: Observed() {
    private var _state:GalleryCTXState = GalleryCTXState()
    private var _chosenPhoto:PhotoDTO? = null


    fun getState() = _state
    fun getWidthsOfThumbs():Int = _state.thumbWidths.values.sum()
    fun getThumbCount():Int  = _state.thumbWidths.size

    fun clearCTX(){
        _state = GalleryCTXState()
        _chosenPhoto = null
    }

    fun handleAction(action:GalleryCTXAction){
        var invoke = true
        when(action){
            is GalleryCTXAction.ReportThumbLoaded -> {
                val updatedThumbSet = _state.thumbWidths
                val updatedThumbWidthsMaster = _state.thumbWidthsMaster
                val updatedThumbParentDtos = _state.thumbParentDtos
                updatedThumbSet.put(action.fileName, action.width)
                updatedThumbWidthsMaster.put(action.fileName, action.width)
                updatedThumbParentDtos.put(action.fileName, PhotoService.getPhotoByFileName(action.fileName) ?: throw Exception("could not find dto for ${action.fileName}"))
                _state = _state.copy(thumbWidths = updatedThumbSet, thumbParentDtos = updatedThumbParentDtos, thumbWidthsMaster = updatedThumbWidthsMaster)
            }
            is GalleryCTXAction.HandleThumbDblClick ->{
                _state = _state.copy(chosenPhoto = action.dto)
            }
            is GalleryCTXAction.HandleShowTagsClicked -> {
                _state = _state.copy(showTags = !_state.showTags)
            }
            is GalleryCTXAction.HandleShowTitleClicked -> {
                _state = _state.copy(showTitle = !_state.showTitle)
            }
            is GalleryCTXAction.HandleAddWantedTag -> {
                var wanted = _state.wantedTags.toMutableSet()
                var unwanted = _state.unwantedTags.toMutableSet()
                wanted.remove(action.tag)
                unwanted.add(action.tag)
                _state = _state.copy(unwantedTags = unwanted, wantedTags = wanted, thumbWidths = cleanThumbParentDtos(wanted))
            }
            is GalleryCTXAction.HandleRemoveWantedTag -> {
                var wanted = _state.wantedTags.toMutableSet()
                var unwanted = _state.unwantedTags.toMutableSet()
                wanted.add(action.tag)
                unwanted.remove(action.tag)
                _state = _state.copy(unwantedTags = unwanted, wantedTags = wanted, thumbWidths = cleanThumbParentDtos(wanted))
            }
            is GalleryCTXAction.HandleBuildingOfMaps -> {
                val port:MutableMap<Int, PhotoDTO> = mutableMapOf()
                val land:MutableMap<Int, PhotoDTO> = mutableMapOf()
                action.photos.forEachIndexed { index, photoDTO ->
                    if (isPortrait(photoDTO)) {
                        port.put(index, photoDTO)
                    } else {
                        land.put(index, photoDTO)
                    }
                }
                val wanted = mutableSetOf<String>()
                action.photos.forEach { photoDTO ->
                    photoDTO.tags.forEach {
                        wanted.add(it.detail)
                    }
                }
                _state = _state.copy()
                _state = _state.copy(photoMapsFinished = true, photoMapPort = port, photoMapLand = land,wantedTags = wanted)
            }
        }
        if(invoke){
            invokeCbs()
        }
    }

    private fun isPortrait(dto: PhotoDTO): Boolean {
        return dto.heightFull >= dto.widthFull
    }

    fun cleanThumbParentDtos( wanted:Set<String>):  MutableMap<String, Int>{
        var updatedThumbWidth: MutableMap<String, Int> = mutableMapOf()
        _state.thumbParentDtos.forEach { entry ->
            if(entry.value.tags.map { it.detail }.intersect(wanted).size > 0){
                val fileName = entry.value.fileName
                val width = _state.thumbWidthsMaster[fileName] ?: throw Exception("Could not find $fileName in _state.thumbWidthsMaster")
                updatedThumbWidth.put(fileName, width)
            }
        }
        return updatedThumbWidth
    }
}