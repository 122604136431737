package apofnj.components.imageGallery


import apofnj.store.GalleryService
import apofnj.store.PhotoService
import apofnj.store.Store
import net.ideablender.apofnj.dto.GalleryDTO
import net.ideablender.apofnj.pojo.GalleryName
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.div
import react.dom.h1

interface ImageGalleryWrapperState : RState {
}

interface ImageGalleryWrapperProps : RProps {
    var galleryName: GalleryName
}

/*
* going to put the tag sorting here
* */
class ImageGalleryWrapper : RComponent<ImageGalleryWrapperProps, ImageGalleryWrapperState>() {
    override fun ImageGalleryWrapperState.init() {
        Store.registerCallback(key = "ImageGallery", ::onStoreChange)
    }

    override fun componentWillUnmount() {
        Store.unRegisterCallback(key = "ImageGallery")
    }

    fun onStoreChange(){
        this.forceUpdate()
    }

    override fun RBuilder.render() {
        console.log(props.galleryName)
        div("ImageGalleryWrapper") {
            if(Store.getAppLoaded()){
                val _gallery : GalleryDTO = GalleryService.getGallery(props.galleryName) ?:  throw Exception("Could not find a gallery for ${props.galleryName}")
                val _photos = PhotoService.getPhotosForGallery(_gallery)
                if(!_photos.isEmpty()){
                    child(ImageGallery::class) {
                        attrs.gallery = _gallery
                        attrs.photos = _photos
                    }
                }else{
                    h1{
                        +"No photos for gallery"
                    }
                }

            }else{
                h1{
                    +"not loaded"
                }
            }
        }
    }
}