package apofnj.components.imageGallery

import apofnj.bindings.Boundary
import apofnj.bindings.ReactDraggable
import apofnj.store.GalleryCTX
import apofnj.store.GalleryCTXAction
import apofnj.store.Store
import kotlinx.html.id
import react.*
import react.dom.div



interface ThumbContainerProps : RProps {
    var width:Int
    var boundary : Boundary
}


class ThumbContainer : RComponent<ThumbContainerProps, RState>() {
    override fun componentDidMount() {
        GalleryCTX.registerCallback("ThumbContainer", ::handleGalleryCtxChange)
    }

    fun handleGalleryCtxChange(){
        forceUpdate()
    }

    fun RBuilder.getDiv(){
        div {
            attrs.id = "ThumbContainer"
            GalleryCTX.getState().photoMapLand.forEach { mapEntry ->
                if(GalleryCTX.getState().wantedTags.intersect(mapEntry.value.tags.map{it.detail}).isNotEmpty()){
                    child(ThumbImage::class){
                        attrs.photoDTO = mapEntry.value
                        attrs.position = mapEntry.key
                    }
                }
            }
        }
    }

    override fun RBuilder.render() {
        if(Store.isCLientPhone()){
            getDiv()
        }else{
            ReactDraggable{
                attrs.axis = "x"
                attrs.bounds = props.boundary
                getDiv()
            }
        }
    }
}