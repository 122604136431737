package apofnj.pages.cd

import apofnj.buildTitleTag
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.div
import react.dom.h2
import react.helmet.helmet

class CDMatterport : RComponent<RProps, RState>() {
    private val title = "Construction Documentation | Matterport Documentation"
    override fun RBuilder.render() {
        div("PAGE CDMatterport") {
            helmet(title = buildTitleTag(title))
            h2 {
                +title
            }
        }
    }
}