package apofnj.pages

import apofnj.bindings.reactmapgldraw.isNullUndefinedOrEmpty
import apofnj.bindings.reactselect.ReactSelect
import apofnj.buildTitleTag
import apofnj.components.imageGallery.ImageGalleryMenu
import apofnj.components.mapping.FormData
import apofnj.crScrope
import apofnj.jsonClient
import apofnj.store.ModalCtx
import apofnj.store.ServerClient
import apofnj.store.getServerBaseUrl
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.coroutines.launch
import kotlinx.html.ButtonType
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onClickFunction
import net.ideablender.apofnj.dto.ContactFormDTO
import net.ideablender.apofnj.req.AddressRequest
import net.ideablender.apofnj.req.ContactFormREQ
import net.ideablender.apofnj.statics.LIST_STATES
import org.w3c.dom.HTMLTextAreaElement
import react.*
import react.dom.*
import react.helmet.helmet


enum class ContactFormStatus{
    PRESEND,
    SUCCESS,
    FAIL
}
class Contact : RComponent<RProps, ContactState>() {
    override fun ContactState.init() {
        req = ContactFormREQ()
        status = ContactFormStatus.PRESEND
    }
    private fun RBuilder.markRequired(){
        div("formRequired"){
            +"*"
        }
    }
    private fun onInputChange(fd: FormData) {
        var newReq = state.req
        when (fd.param) {
            "name" -> newReq = newReq.copy(name = fd.value as String )
            "email" -> newReq = newReq.copy(email = fd.value as String )
            "msg" -> newReq = newReq.copy(msg = fd.value as String )
        }
        setState {
            req = newReq
        }
    }

    fun isFormDisabled():Boolean{
        val req = state.req
        if(req.name.isNullUndefinedOrEmpty()) return true
        if(req.email.isNullUndefinedOrEmpty()) return true
        if(req.msg.isNullUndefinedOrEmpty()) return true

        return false
    }

    override fun RBuilder.render() {
        div("PAGE Contact") {
            helmet(title = buildTitleTag("Contact Us"))
            h2 {
                +"Contact"
            }

            when(state.status){
                ContactFormStatus.PRESEND -> {
                    p{
                        +"Want more information on how we can help you build your brand excellence? Fill out the below and we’ll get back to you right away. Need answers right away? Call 908-730-0202."
                    }
                    div("card addBottomMargin"){
                        div("cardHeader"){
                            +"Talk To Us"
                        }
                        div("cardBody "){
                            form(classes = "pure-form pure-form-aligned"){
                                val req = state.req
                                fieldSet {
                                    div("pure-control-group"){
                                        label {
                                            markRequired()
                                            +"Name:"
                                        }
                                        input(type = InputType.text, name = "name") {
                                            attrs.value = req.name ?: ""
                                            attrs.onChangeFunction = {
                                                onInputChange(FormData.eventToFormData(it))
                                            }
                                        }
                                    }
                                    div("pure-control-group"){
                                        label {
                                            markRequired()
                                            +"Email:"
                                        }
                                        input(type = InputType.text, name = "email") {
                                            attrs.value = req.email ?: ""
                                            attrs.onChangeFunction = {
                                                onInputChange(FormData.eventToFormData(it))
                                            }
                                        }
                                    }
                                    div("pure-control-group"){
                                        textArea(classes = "") {
                                            attrs.value = req.msg ?: ""
                                            attrs.name = "msg"
                                            attrs.onChangeFunction = {
                                                val target = it.target as HTMLTextAreaElement
                                                onInputChange(FormData(label = "msg", value = target.value.take(250), param = "msg"))
                                            }
                                        }
                                    }
                                    button(classes = "pure-button button-primary", type = ButtonType.button){
                                        +"Send"
                                        val contactFormREQ = ContactFormREQ(name = req.name, email = req.email, msg = req.msg)
                                        attrs.disabled = isFormDisabled()
                                        attrs.onClickFunction = {
                                            ModalCtx.openModal {
                                                child(ProcessingRequest::class){}
                                            }
                                            crScrope.launch {
                                                val resp  = ServerClient.sendContactForm(contactFormREQ)
/*
val resp = jsonClient.post<Boolean>("${getServerBaseUrl()}${ContactFormDTO.getRestPath()}") {
contentType(ContentType.Application.Json)
body = req
}*/
                                                var _status: ContactFormStatus? = null
                                                if(resp){
                                                    _status = ContactFormStatus.SUCCESS
                                                }else{
                                                    _status = ContactFormStatus.FAIL
                                                }
                                                setState {
                                                    status = _status
                                                }
                                                ModalCtx.closeModal()
                                                console.log("Contact", resp)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                ContactFormStatus.SUCCESS -> {
                    p{
                        +"Thank you for contacting us. We have received your message."
                    }
                }
                ContactFormStatus.FAIL -> {
                    p{
                        +"Something went wrong."
                    }
                }
            }


        }
    }
}
interface ContactState : RState {
    var req : ContactFormREQ
    var status: ContactFormStatus
}

interface ContactProps : RProps {
}

