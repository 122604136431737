package apofnj.pages.cre

import apofnj.buildTitleTag
import apofnj.components.imageGallery.ImageGalleryWrapper
import net.ideablender.apofnj.pojo.GalleryName
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.*
import react.helmet.helmet

class CREDrone : RComponent<RProps, RState>() {
    private val title = "Commercial Real Estate | Drone"
    override fun RBuilder.render() {
        div("PAGE CREDrone") {
            helmet(title = buildTitleTag(title))
            h2 {
                +title
            }
            p{
                +"Is Drone aerial photography a good fit for your OM, or would you be better off with traditional aerial photography from our fixed wing Cessna? Here’s some pro’s and con’s of both to help you decide…"
            }
            p("ulHead"){
                +"Drone - Pros"
            }

            ul{

                li{
                    +"Less expensive, usually between \$450 and \$550, depending on location"
                }

                li{
                    +"Easier weather requirements (faster turnaround time) because we need less visibility"
                }

                li{
                    +"Can supply tight close up’s for full frame still photos"
                }
                li{
                    +"Unparalleled when it comes to aerial video quality"
                }
                li{
                    +"Great for dusk photos/video"
                }
            }

            p("ulHead"){
                +"Drone - Cons"
            }
            ul{

                li{
                    +"Smaller camera sensor and lower quality. Not noticeable on the web, but if you plan to make prints of any size or would like to zoom in, you’re better off with a professional camera"
                }
                li{
                    +"Less photo angles available because a drone is not allowed to loiter over people or moving cars"
                }
                li{
                    +"Use is restricted close to major airports"
                }
                li{
                    +"Limited in altitude to 400’. Much smaller field of view. Drones cannot capture large sites in one photo, and highway intersections and other assets more than a mile away are not clearly visible. See below for photo comparison"
                }
            }

            p("ulHead"){
                +"Cessna - Pros"
            }
            ul{

                li{
                    +"Much better field of view. See below"
                }
                li{
                    +"Much better-quality photographs, up to 130 MB in size to make large prints and allow super tight zoom-in’s on digital files"
                }
                li{
                    +"More angles available. Not restricted in flying over roads and people like a drone is. "
                }
                li{
                    +"No restrictions close to major airports like a drone has\n"
                }
                li{
                    +"Lenses up to 600MM allow tight closeups and show great detail\n"
                }
            }

            p("ulHead"){
                +"Cessna - Cons"
            }
            ul{

                li{
                    +"More expensive, usually between \$700 and \$775, depending on location."
                }
                li{
                    +"Not good for video, unless it’s wide angle only"
                }
                li{
                    +"Need better weather since we can include assets many miles away"
                }
                li{
                    +"Not a great fit for dusk aerial photography"
                }
            }

            p{
                +"What about including both Drone and Cessna flown aerial photos in your OM? When would that be a good option? If you want video, or any kind of dusk photography, there’s no better platform than a Drone. We shoot 4K with our Drones (we have 5 of them) and the results are outstanding. Not just the 4K quality - the stabilization is amazing. "
            }

            p{
                +"Any question? Call us at 908-730-0202 and we’ll be happy to help you figure out what works best for you.\n"
            }

            child(ImageGalleryWrapper::class) {
                attrs.galleryName = GalleryName.CRE_DRONE
            }
        }
    }
}