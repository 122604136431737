package apofnj.pages

import apofnj.buildTitleTag
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.div
import react.dom.h2
import react.helmet.helmet

class Testimonials : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        div("PAGE Testimonials") {
            helmet(title = buildTitleTag("Testimonials"))
            h2 {
                +"Testimonials"
            }
        }
    }
}