package apofnj

import apofnj.components.BoundaryAwareComponent
import apofnj.components.BoundaryAwareComponentProps
import apofnj.components.BoundaryAwareComponentState
import apofnj.helpers.SizeAwarePref
import apofnj.store.ModalCtx
import apofnj.store.Store
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.css.*
import kotlinx.html.ButtonType
import kotlinx.html.classes
import kotlinx.html.js.onClickFunction
import react.*
import react.dom.*
import react.dom.p
import react.router.dom.useHistory
import styled.css
import styled.styledDiv

interface HeroImageProps: BoundaryAwareComponentProps {
    override var pageTag: String
    override var pref: SizeAwarePref
    var imgUrl:String
}

interface HeroImageState: BoundaryAwareComponentState {
    var imgUrl:String?
}

class HeroImage : BoundaryAwareComponent<HeroImageProps, HeroImageState>() {
    override fun componentWillMount() {
        super.componentWillMount()
    }

    override fun componentWillUnmount() {
        super.componentWillUnmount()
    }
    private fun getImagePath():String{
/*        if(Store.isDesktop()){
            return "/img/20070621_1100_Cover5000.jpg"
        }else{
            return "/img/aerial_port_1_mod.jpg"
        }*/

        return "/img/20070621_1100_Cover5000.jpg"
    }
    override fun RBuilder.render() {

        if(state.boundary != undefined){
            console.log(window.innerWidth)
            console.log(document.documentElement?.clientWidth)
            styledDiv{
                attrs.classes = setOf("HeroImage")
                css {
                    height = 1.px * state.boundary.height
                    width = 1.px * state.boundary.width
                    backgroundImage = Image("url('${getImagePath()}')")
                    backgroundRepeat = BackgroundRepeat.noRepeat
                    backgroundPosition = "center"
                    backgroundSize = "cover"
                    display = Display.block
                }
                div("storyWrapper"){
                    div("content"){
                        h3{
                           +"Do You Need Excellence in Marketing Media?"
                        }
                        p {
                            +"Great - we can help!"
                        }
                        p {
                            +"Aerial Photos of NJ has been the go-to photography firm for the CRE industry "
                            b{
                                +"since 1999!"
                            }
                        }
                        p {
                            +"Let’s get you started by clicking below & using our Easy Site Locator to outline your property & get a quick estimate for your Aerial or Architectural photography needs."
                        }
                        div("btnWrapper"){
                            child(getStartedButton)
                        }
                    }
                }
            }
        }
    }
}

val getStartedButton = functionalComponent<RProps> {
    val history = useHistory()
    button(classes = "pure-button button-secondary", type = ButtonType.button){
        attrs.onClickFunction = {
            history.push(NAV_KEY_SITE_LOCATOR)
        }
        +"Get Started"
    }
}