package apofnj

import apofnj.helpers.BoundaryAwareSupport
import apofnj.helpers.Box
import apofnj.pojos.URL_HANDLES
import apofnj.store.*
import io.ktor.client.HttpClient
import io.ktor.client.features.defaultRequest
import io.ktor.client.features.json.JsonFeature
import io.ktor.client.features.json.serializer.KotlinxSerializer
import io.ktor.client.request.header
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import net.ideablender.apofnj.dto.PhotoDTO
import org.w3c.xhr.XMLHttpRequest
import react.dom.render

val crScrope = MainScope()
val endpoint = window.location.origin
val TEST_URL = "$endpoint/#$PHOTO_STATIC/${URL_HANDLES.ENTITY_ID}=3"
fun getDataUrlId():Int?{
    var id:Int? = null
    val idString = window.location.href.substringAfterLast(URL_HANDLES.ENTITY_ID.toString()).replace("=", "")
    if(idString.length > 0 && window.location.href.contains(URL_HANDLES.ENTITY_ID.toString())){
        id = idString.toInt()
    }
    return id
}

fun Long.toDateTime() = Instant.fromEpochMilliseconds(this).toLocalDateTime(TimeZone.UTC)

fun Long.toDateTimeFormatted():String{
    val dt = this.toDateTime()
    return "${dt.monthNumber}/${dt.dayOfMonth}/${dt.year} ${dt.hour}:${dt.minute}"
}
data class ReactSelectOption(val value: String = "0", val label: String = "Please Choose One")

val jsonClient = HttpClient {
    install(JsonFeature) { serializer = KotlinxSerializer() }
    defaultRequest {
        header("Access-Control-Allow-Origin", "*")
    }
}

val THRESHOLD = 50
val EXTENSION_PHOTO = "jpg"
val TXT_THUMB_NAME_MODIFIER = "-thumb"
val imgWebAssress = getImgWebAddy()

private fun getImgWebAddy():String{
    return if(window.location.hostname == "localhost"){
        "http://localhost:8080/images/"
    }else{
        "https://admin.aerialphotosofnj.com/images/"
    }
}


fun generateThumbFileName(fileName: String) = fileName.replace(".$EXTENSION_PHOTO", "$TXT_THUMB_NAME_MODIFIER.$EXTENSION_PHOTO")
fun getPathForImage(dto: PhotoDTO) = "${imgWebAssress}/${dto.fileName}"
fun getPathForThumb(dto:PhotoDTO):String{
    val retval = "${imgWebAssress}/${generateThumbFileName(dto.fileName)}"
    return retval
}

fun main() {
    window.addEventListener("resize", {
        if(window.document.body != null){
            setWindowSize()
            console.log("resize two")
        }
    })
    window.addEventListener("resize", {
        BoundaryAwareSupport.recordDivSizes()
        console.log("resize one")
    })
    window.onload = {
        render(document.getElementById("root")) {
            child(Index::class) { }
        }
    }
    setWindowSize()
    crScrope.launch{
        GalleryService.fetchAll()
        TagService.fetchAll()
        PhotoService.fetchAll()
        ServerClient.requestMapboxKey()
    }
}

fun setWindowSize(){
    ElementSizeCTX.setBox(
        Box(
        height = window.innerHeight,
        width = window.innerWidth)
    )
}

fun buildTitleTag(str: String): String = "$META_TITLE_ROOT | $str"
fun buildTitleTagForPhoto(photoTitle: String, galleryTitle: String): String = "$META_TITLE_ROOT | Photos | $galleryTitle | $photoTitle"



data class DimensionsCtx(val width:Int, val height:Int)
class HtmlElementNotFoundForIdException(id: String) : Exception("Could not find an HTML element for css id : $id")
fun getElementDimensionById(id: String): DimensionsCtx {
    val ele = kotlinx.browser.document.getElementById(id)
    if(ele == null){
        throw HtmlElementNotFoundForIdException(id)
    }else{
        return DimensionsCtx(height = ele.clientHeight, width = ele.clientWidth)
    }
}
