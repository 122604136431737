package apofnj.pages.cre

import apofnj.buildTitleTag
import apofnj.components.imageGallery.ImageGalleryWrapper
import net.ideablender.apofnj.pojo.GalleryName
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.div
import react.dom.h2
import react.helmet.helmet

class CREInterior : RComponent<RProps, RState>() {
    private val title = "Commercial Real Estate | Interior"
    override fun RBuilder.render() {
        div("PAGE CREInterior") {
            helmet(title = buildTitleTag(title))
            h2 {
                +title
            }
            child(ImageGalleryWrapper::class) {
                attrs.galleryName = GalleryName.CRE_ARCH
            }
        }
    }
}