package apofnj

import apofnj.components.PhotoStatic
import apofnj.helpers.BoundaryAwareSupport
import apofnj.modal.ModalFull
import apofnj.pages.*
import apofnj.pages.cd.*
import apofnj.pages.cre.*
import apofnj.store.GalleryCTX
import apofnj.store.ModalCtx
import apofnj.store.Store
import kotlinx.html.id
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.div
import react.router.dom.*


@JsExport
class Index : RComponent<RProps, RState>() {
    override fun componentDidMount() {
        BoundaryAwareSupport.recordDivSizes()
    }
    override fun RBuilder.render() {
        val foobar = Store.md
         div {
            attrs.id = "SiteRoot"
            child(ModalFull::class){}
            hashRouter {
                child(NavBar::class){}
                switch {
                    route("/", true) {
                        redirect(to = NAV_KEY_HOME)
                    }
                    route(NAV_KEY_HOME, true) {
                        Store.setCurrentPage(NAV_KEY_HOME)
                        child(Home::class) {}
                    }
                    route(NAV_KEY_SITE_MAP, true) {
                        Store.setCurrentPage(NAV_KEY_SITE_MAP)
                        child(SiteMap::class) {}
                    }
                    route(PHOTO_STATIC, true) {
                        Store.setCurrentPage(PHOTO_STATIC)
                        child(PhotoStatic::class) {}
                    }
                    route(NAV_KEY_MAPPING, true) {
                        Store.setCurrentPage(NAV_KEY_MAPPING)
                        child(Mapping::class) {}
                    }
                    route(NAV_KEY_CONTACT, true) {
                        Store.setCurrentPage(NAV_KEY_CONTACT)
                        child(Contact::class) {}
                    }
                    route(NAV_KEY_SITE_LOCATOR, true) {
                        Store.setCurrentPage(NAV_KEY_SITE_LOCATOR)
                        child(SiteLocator::class) {}
                    }
                    route(NAV_KEY_ABOUT_US, true) {
                        Store.setCurrentPage(NAV_KEY_ABOUT_US)
                        child(About::class) {}
                    }
                    route(NAV_KEY_COMMERCIAL_REAL_ESTATE, true) {
                        Store.setCurrentPage(NAV_KEY_COMMERCIAL_REAL_ESTATE)
                        child(CREIndex::class) {}
                    }
                    route(NAV_KEY_CRE_AERIAL, true) {
                        GalleryCTX.clearCTX()
                        Store.setCurrentPage(NAV_KEY_CRE_AERIAL)
                        child(CREAerial::class) {}
                    }
                   route(NAV_KEY_CRE_DRONE, true) {
                        GalleryCTX.clearCTX()
                        Store.setCurrentPage(NAV_KEY_CRE_DRONE)
                        child(CREDrone::class) {}
                    }
                    route(NAV_KEY_CRE_ARCH, true) {
                        GalleryCTX.clearCTX()
                        Store.setCurrentPage(NAV_KEY_CRE_ARCH)
                        child(CREArchitectural::class) {}
                    }
                    route(NAV_KEY_CRE_INTERIOR, true){
                        GalleryCTX.clearCTX()
                        Store.setCurrentPage(NAV_KEY_CRE_INTERIOR)
                        child(CREInterior::class) {}
                    }
                    route(NAV_KEY_CRE_MATTER_PORT, true) {
                        GalleryCTX.clearCTX()
                        Store.setCurrentPage(NAV_KEY_CRE_MATTER_PORT)
                        child(CREMatterport::class) {}
                    }
                    route(NAV_KEY_CRE_VIDEO, true) {
                        GalleryCTX.clearCTX()
                        Store.setCurrentPage(NAV_KEY_CRE_VIDEO)
                        child(CREVideo::class) {}
                    }
                    route(NAV_KEY_CONSTRUCTION_PROGRESS, true) {
                        GalleryCTX.clearCTX()
                        Store.setCurrentPage(NAV_KEY_CONSTRUCTION_PROGRESS)
                        child(CDIndex::class) {}
                    }
                    route(NAV_KEY_CP_CONSTRUCTION_CAM, true) {
                        GalleryCTX.clearCTX()
                        Store.setCurrentPage(NAV_KEY_CP_CONSTRUCTION_CAM)
                        child(CDConstructionCam::class) {}
                    }
                    route(NAV_KEY_CP_AERIAL, true) {
                        GalleryCTX.clearCTX()
                        Store.setCurrentPage(NAV_KEY_CP_AERIAL)
                        child(CDAerial::class) {}
                    }
                    route(NAV_KEY_CP_GROUND, true) {
                        GalleryCTX.clearCTX()
                        Store.setCurrentPage(NAV_KEY_CP_GROUND)
                        child(CDGround::class) {}
                    }
                    route(NAV_KEY_CP_MATTER_PORT, true) {
                        GalleryCTX.clearCTX()
                        Store.setCurrentPage(NAV_KEY_CP_MATTER_PORT)
                        child(CDMatterport::class) {}
                    }
                    route(NAV_KEY_PRIVACY_POLICY, true) {
                        Store.setCurrentPage(NAV_KEY_CP_VIDEO)
                        child(PrivacyPolicy::class) {}
                    }
                    route(NAV_KEY_TESTIMONIALS, true) {
                        Store.setCurrentPage(NAV_KEY_TESTIMONIALS)
                        child(Testimonials::class) {}
                    }
                }
                child(Footer::class) {}
            }
        }
    }
}

